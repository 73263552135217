import {stateType} from "../.."

export enum PreeclampsiaActionKind {
  UPDATE_EPIDEMIOLOGIC = "UPDATE_EPIDEMIOLOGIC",
  UPDATE_BIOMETRIC = "UPDATE_BIOMETRIC",
  UPDATE_FTD = "UPDATE_FTD",
  UPDATE_OUTPUT = "UPDATE_OUTPUT",
}

interface PreeclampsiaModule {
  action: PreeclampsiaActionKind
  payload: string | {[key: string]: string}
}

export const preeclampsiaReducer = (
  state: stateType,
  {action, payload}: PreeclampsiaModule
) => {
  switch (action) {
    case PreeclampsiaActionKind.UPDATE_EPIDEMIOLOGIC:
      return {
        ...state,
        preeclampsiaValues: {...state.preeclampsiaValues, epidemiologic: payload},
      }
    case PreeclampsiaActionKind.UPDATE_BIOMETRIC:
      return {
        ...state,
        preeclampsiaValues: {...state.preeclampsiaValues, biometric: payload},
      }
    case PreeclampsiaActionKind.UPDATE_FTD:
      return {
        ...state,
        preeclampsiaValues: {...state.preeclampsiaValues, ftd: payload},
      }
    case PreeclampsiaActionKind.UPDATE_OUTPUT:
      return {
        ...state,
        preeclampsiaValues: {...state.preeclampsiaValues, output: payload},
      }
    default:
      return state
  }
}
