import {
  CerebroPlacentalInitStateType,
  GenericPIInitStateType,
  TEIInitStateType,
  UterineInitStateType,
} from "../../store/Initials/doppler"

export const artertiesRangesArray = [
  2600, 2180, 1970, 1810, 1645, 1600, 1520, 1450, 1380, 1310, 1260, 1200, 1170, 1100,
  1070, 1020, 980, 940, 900, 860, 840, 790, 760, 720, 690, 670, 630, 310, 570, 540,
  510, 480, 460, 430, 400, 370, 340, 320, 300, 270, 240, 220, 190, 170, 140, 120, 90,
  70, 50, 30, -10, -35, -60, -80, -110, -130, -160, -185, -210, -240, -260, -280,
  -310, -340, -36, -400, -430, -450, -480, -500, -540, -565, -585, -620, -650, -690,
  -730, -750, -790, -830, -850, -890, -930, -970, -1010, -1040, -1100, -1140, -1200,
  -1250, -1310, -1370, -1430, -1510, -1600, -1645, -1810, -1970, -2180, -2600,
]

export const findInartertiesRangesArray = (
  value: number,
  aPosition: number,
  diffToNext: number
): string => {
  if (aPosition < 0) return ">99"
  if (aPosition >= artertiesRangesArray.length) return "<1"
  if (
    value >= artertiesRangesArray[aPosition] &&
    value < artertiesRangesArray[aPosition - 1]
  ) {
    return `${100 - aPosition}`
  } else if (value > artertiesRangesArray[aPosition]) {
    aPosition = aPosition - diffToNext
    diffToNext = Math.round(diffToNext / 2)
    return findInartertiesRangesArray(value, aPosition, diffToNext)
  } else {
    aPosition = aPosition + diffToNext
    diffToNext = Math.round(diffToNext / 2)
    return findInartertiesRangesArray(value, aPosition, diffToNext)
  }
}

export type GenericCalcProps = {
  genericValues: GenericPIInitStateType
  dispatch: (percentil: string, result: string) => void
}

export const calcUmmbilicalArtery = ({
  genericValues,
  dispatch,
}: GenericCalcProps) => {
  const pi = Number(genericValues.pulsabilityIndex)
  const totalWeeks = Number(genericValues.weeks) + Number(genericValues.days) / 7
  const vi =
    (pi - (3.55219 - 0.13558 * totalWeeks + 0.00174 * (totalWeeks * totalWeeks))) /
    0.299
  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi > 1.645 ? "Pathological" : "Normal"
  dispatch(per, res)
}

export const calcMCA = ({genericValues, dispatch}: GenericCalcProps) => {
  const pi = Number(genericValues.pulsabilityIndex)
  const totalWeeks = Number(genericValues.weeks) + Number(genericValues.days) / 7
  const vi =
    (pi - (-2.7317 + 0.3335 * totalWeeks - 0.0058 * (totalWeeks * totalWeeks))) /
    (-0.88005 + 0.08182 * totalWeeks - 0.00133 * (totalWeeks * totalWeeks))
  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi >= -1.645 ? "Normal" : "Pathological"
  dispatch(per, res)
}

export type CerebroPlacentalProps = {
  cerebroValues: CerebroPlacentalInitStateType
  dispatch: (cerebroRatio: string, percentil: string, result: string) => void
}

export const calcCerebro = ({cerebroValues, dispatch}: CerebroPlacentalProps) => {
  const {picma, piua} = cerebroValues
  const totalWeeks = Number(cerebroValues.weeks) + Number(cerebroValues.days) / 7

  const relationCerebroPlacental = Number(picma) / Number(piua)
  const vi =
    (relationCerebroPlacental -
      (-4.0636 + 0.383 * totalWeeks - 0.0059 * (totalWeeks * totalWeeks))) /
    (-0.9664 + 0.09027 * totalWeeks - 0.0014 * (totalWeeks * totalWeeks))

  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi >= -1.645 ? "Normal" : "Pathological"
  dispatch(`${relationCerebroPlacental}`, per, res)
}

export type UterineArteriesProps = {
  uterineValues: UterineInitStateType
  dispatch: (avrgpi: string, percentil: string, result: string) => void
}

export const calcUterineArteries = ({
  uterineValues,
  dispatch,
}: UterineArteriesProps) => {
  const {pirua, pilua, weeks, days} = uterineValues
  const sevenTimesWeeksPlusDays = 7 * Number(weeks) + Number(days)

  const piPromed = (Number(pilua) + Number(pirua)) / 2
  const logPiPromed = Math.log(piPromed)
  const vi =
    (logPiPromed -
      (1.39 -
        0.012 * sevenTimesWeeksPlusDays +
        198e-7 * (sevenTimesWeeksPlusDays * sevenTimesWeeksPlusDays))) /
    (0.272 - 259e-6 * sevenTimesWeeksPlusDays)

  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi <= 1.645 ? "Normal" : "Pathological"
  dispatch(`${piPromed}`, per, res)
}

export const calcDuctus = ({genericValues, dispatch}: GenericCalcProps) => {
  const pi = Number(genericValues.pulsabilityIndex)
  const totalWeeks = Number(genericValues.weeks) + Number(genericValues.days) / 7

  const vi = (pi - (0.903 + -0.0116 * totalWeeks)) / 0.1483
  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi <= 1.645 ? "Normal" : "Pathological"
  dispatch(per, res)
}

export const calcIsthmus = ({genericValues, dispatch}: GenericCalcProps) => {
  const pi = Number(genericValues.pulsabilityIndex)
  const totalWeeks = Number(genericValues.weeks) + Number(genericValues.days) / 7

  const vi =
    (pi - (2.2562 + 0.0154 * totalWeeks)) / (0.014199 + 0.011635 * totalWeeks)
  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi <= 1.645 ? "Normal" : "Pathological"
  dispatch(per, res)
}

export type TEIProps = {
  teiValues: TEIInitStateType
  dispatch: (teiIndex: string, percentil: string, result: string) => void
}

export const calcTEI = ({teiValues, dispatch}: TEIProps) => {
  const {ict, irt, et} = teiValues
  const totalWeeks = Number(teiValues.weeks) + Number(teiValues.days) / 7

  const teiIndex = (Number(ict) + Number(irt)) / Number(et)
  const logTei = Math.log(teiIndex)
  const vi =
    (logTei -
      (-1.22 +
        0.0477 * totalWeeks -
        0.002565 * (totalWeeks * totalWeeks) +
        43e-6 * (totalWeeks * totalWeeks * totalWeeks))) /
    (0.2129 - 0.00566 * totalWeeks + 14e-5 * (totalWeeks * totalWeeks))
  const i = artertiesRangesArray.length / 2
  const per = findInartertiesRangesArray(vi * 1000, i, i / 2)
  const res = vi >= -1.645 ? "Normal" : "Pathological"
  dispatch(`${teiIndex}`, per, res)
}
