import React from "react"
import {StyledDiagnosticAlgorithm, StyledHeader} from "./styles"
import {useTranslation} from "react-i18next"
import ConfirmedLMP from "./ConfirmedLMP"
import Line from "../../../components/Line"
import TwinsAlgorithm from "./TwinsAlgorithm"

const DiagnosticAlgorithm = (): JSX.Element => {
  const {t} = useTranslation("translation")

  return (
    <StyledDiagnosticAlgorithm>
      <StyledHeader>{t("monochronicTwins.diagnosticAlgorithm.header")}</StyledHeader>

      <ConfirmedLMP />

      <Line top={15} bottom={20} />

      <TwinsAlgorithm />
    </StyledDiagnosticAlgorithm>
  )
}

export default DiagnosticAlgorithm
