import React from "react"
import {StyledDropDownBody} from "./styles"

type DropDownBodyProps = {
  children: React.ReactNode
  white?: boolean
  nested?: boolean
}

const DropDownBody = ({children, white, nested}: DropDownBodyProps): JSX.Element => (
  <StyledDropDownBody white={white} nested={nested}>
    {children}
  </StyledDropDownBody>
)

export default DropDownBody
