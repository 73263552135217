import React from "react"
import ImageHeader from "../../components/ImageHeader"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"
import DiagnosticAlgorithm from "./DiagnosticAlgorithm"
import Recomendations from "./Recomendations"
import {StyledMonochronicTwins} from "./styles"

const MonochronicTwins = (): JSX.Element => {
  return (
    <StyledMonochronicTwins>
      <ImageHeader module={StateModuleKind.MONOCHRONICTWINS} />
      <Recomendations />
      <DiagnosticAlgorithm />
    </StyledMonochronicTwins>
  )
}

export default MonochronicTwins
