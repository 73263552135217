import React from "react"
import {StyledTwinsAlgorithm, StyledColumnContainer} from "./styles"
import Form from "../../../../components/Form"
import BasicOutput from "../../../../components/BasicOutput"
import TwinsAlgorithmSchema from "../../../../components/Form/Validations/TwinsAlgorithm"
import ClearValues from "../../../../components/ClearValues"
import CalculateButton from "../../../../components/CalculateButton"
import GenderRadioMT from "./GenderRadioMT"
import {useStore} from "../../../../store"
import {MonochronicTwinsActionKind} from "../../../../store/Reducers/Modules/monochronicTwins"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {InColumnLabel} from "../../../../components/LabelContainer"
import {Column, TextColumn} from "./Column"

const TwinsAlgorithm = (): JSX.Element => {
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(TwinsAlgorithmSchema),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (output: string) => {
    dispatch({
      action: MonochronicTwinsActionKind.UPDATE_GA_BY_LMP,
      payload: output,
    })
  }

  const clearValues = () => {
    dispatch({
      action: MonochronicTwinsActionKind.CLEAR_VALUES,
      payload: {
        confirmedLMP: state.monochronicTwinsValues.confirmedLMP,
        gaByLMP: state.monochronicTwinsValues.gaByLMP,
      },
    })
  }

  return (
    <StyledTwinsAlgorithm>
      <Form handler={handleSubmit(submitHandler)}>
        <GenderRadioMT register={register} errors={errors} />
        <StyledColumnContainer>
          <Column
            errors={errors}
            register={register}
            setRefValue={setValue}
            left
            mvpValue={state.monochronicTwinsValues.maximumVerticalPocketOne}
            mvpSetValue={(v) =>
              dispatch({action: MonochronicTwinsActionKind.UPDATE_MVPO, payload: v})
            }
            bladderValue={state.monochronicTwinsValues.bladderOne}
            bladderSetValue={(v) =>
              dispatch({
                action: MonochronicTwinsActionKind.UPDATE_BLADDER_ONE,
                payload: v,
              })
            }
            efwValue={state.monochronicTwinsValues.estimatedFetalWeightOne}
            efwSetValue={(v) =>
              dispatch({action: MonochronicTwinsActionKind.UPDATE_EFWO, payload: v})
            }
            abdominalCValue={state.monochronicTwinsValues.abdominalCircumferenceOne}
            abdominalCSetValue={(v) =>
              dispatch({
                action: MonochronicTwinsActionKind.UPDATE_ABDOMINAL_ONE,
                payload: v,
              })
            }
            psvValue={state.monochronicTwinsValues.peakSystolicVelocityOne}
            psvSetValue={(v) =>
              dispatch({action: MonochronicTwinsActionKind.UPDATE_PSVO, payload: v})
            }
          />
          <Column
            errors={errors}
            register={register}
            setRefValue={setValue}
            left={false}
            mvpValue={state.monochronicTwinsValues.maximumVerticalPocketTwo}
            mvpSetValue={(v) =>
              dispatch({action: MonochronicTwinsActionKind.UPDATE_MVPT, payload: v})
            }
            bladderValue={state.monochronicTwinsValues.bladderTwo}
            bladderSetValue={(v) =>
              dispatch({
                action: MonochronicTwinsActionKind.UPDATE_BLADDER_TWO,
                payload: v,
              })
            }
            efwValue={state.monochronicTwinsValues.estimatedFetalWeightTwo}
            efwSetValue={(v) =>
              dispatch({action: MonochronicTwinsActionKind.UPDATE_EFWT, payload: v})
            }
            abdominalCValue={state.monochronicTwinsValues.abdominalCircumferenceTwo}
            abdominalCSetValue={(v) =>
              dispatch({
                action: MonochronicTwinsActionKind.UPDATE_ABDOMINAL_TWO,
                payload: v,
              })
            }
            psvValue={state.monochronicTwinsValues.peakSystolicVelocityTwo}
            psvSetValue={(v) =>
              dispatch({action: MonochronicTwinsActionKind.UPDATE_PSVT, payload: v})
            }
          />
          <TextColumn />
        </StyledColumnContainer>

        <InColumnLabel>
          <CalculateButton
            onClick={() => console.log(state.monochronicTwinsValues)}
          />
          <ClearValues onClick={() => clearValues()} />
        </InColumnLabel>

        <BasicOutput value={state.monochronicTwinsValues.outputOne}></BasicOutput>
        <BasicOutput value={state.monochronicTwinsValues.outputTwo}></BasicOutput>
      </Form>
    </StyledTwinsAlgorithm>
  )
}

export default TwinsAlgorithm
