import {yupResolver} from "@hookform/resolvers/yup"
import React, {useEffect, useState} from "react"
import {FieldErrorsImpl, FieldValues, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import BasicInput from "../../../components/BasicInput"
import BasicLabel from "../../../components/BasicLabel"
import BasicOutput from "../../../components/BasicOutput"
import CalculateButton from "../../../components/CalculateButton"
import DropDownBody from "../../../components/DropDown/Body"
import DropDownHeader from "../../../components/DropDown/Header"
import Form from "../../../components/Form"
import UterineArteriesSchema from "../../../components/Form/Validations/UterineArteries"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import {useStore} from "../../../store"
import {UterineInitStateType} from "../../../store/Initials/doppler"
import {DopplerActionKind} from "../../../store/Reducers/Modules/doppler"
import {calcUterineArteries} from "../../../utils/algorithms/doppler"
import {StyledConfinedSpace} from "../styles"

type UterineProps = {
  ManageGAErrors: (errors: FieldErrorsImpl<FieldValues> | undefined) => void
}

const Uterine = ({ManageGAErrors}: UterineProps) => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(UterineArteriesSchema),
  })

  const submitHandler = (values: FieldValues) => {
    ManageGAErrors(undefined)
    calcUterineArteries({
      uterineValues: values as UterineInitStateType,
      dispatch: updateOutputs,
    })
  }
  const errorHandler = (errors: FieldErrorsImpl<FieldValues>) => {
    ManageGAErrors(errors)
  }

  const updateOutputs = (avrgpi: string, percentil: string, results: string) => {
    dispatch({
      action: DopplerActionKind.UPDATE_UTERINE,
      payload: {
        ...state.dopplerValues.uterine,
        avrgpi: avrgpi,
        percentil: percentil,
        results: results,
      },
    })
  }

  useEffect(() => {
    setValue("weeks", state.dopplerValues.weeks)
  }, [state.dopplerValues.weeks])

  useEffect(() => {
    setValue("days", state.dopplerValues.days)
  }, [state.dopplerValues.days])

  return (
    <>
      <DropDownHeader
        name={t("doppler.uterine.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}
      />

      {active && (
        <StyledConfinedSpace>
          <DropDownBody nested white>
            <Form handler={handleSubmit(submitHandler, errorHandler)}>
              <InColumnLabel>
                <BasicLabel name="pirua">{t("doppler.uterine.pirua")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="pirua"
                  type="tel"
                  value={state.dopplerValues.uterine.pirua}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_UTERINE,
                      payload: {...state.dopplerValues.uterine, pirua: v},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="pilua">{t("doppler.uterine.pilua")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="pilua"
                  type="tel"
                  value={state.dopplerValues.uterine.pilua}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_UTERINE,
                      payload: {...state.dopplerValues.uterine, pilua: v},
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <BasicLabel name="avrgpi">{t("doppler.uterine.avrgpi")}</BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.uterine.avrgpi}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="percentil">
                  {t("doppler.uterine.percentil")}
                </BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.uterine.percentil}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="results">
                  {t("doppler.uterine.results")}
                </BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.uterine.results}
                  length={100}
                />
              </InLineLabel>
            </Form>
          </DropDownBody>
        </StyledConfinedSpace>
      )}
    </>
  )
}

export default Uterine
