import React from "react"
import {StyledButton} from "./styles"

export type ButtonProps = {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}

const BasicButton = ({
  active = false,
  onClick,
  children,
}: ButtonProps): JSX.Element => {
  return (
    <StyledButton active={active} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export default BasicButton
