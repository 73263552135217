import React from "react"
import {StyledRecomendations, StyledList, StyledHeader, StyledUL} from "./styles"
import {useTranslation} from "react-i18next"

const Recomendations = (): JSX.Element => {
  const {t} = useTranslation("translation")

  return (
    <StyledRecomendations>
      <StyledHeader>{t("monochronicTwins.recomendations.title")}</StyledHeader>
      <StyledUL>
        <StyledList>{t("monochronicTwins.recomendations.first")}</StyledList>
        <StyledList>{t("monochronicTwins.recomendations.second")}</StyledList>
      </StyledUL>
    </StyledRecomendations>
  )
}

export default Recomendations
