import React, {useState} from "react"
import {StyledFirst} from "./styles"
import DropDownHeader from "../../../../components/DropDown/Header"
import DropDownBody from "../../../../components/DropDown/Body"
import {InColumnLabel} from "../../../../components/LabelContainer"
import BasicLabel from "../../../../components/BasicLabel"
import BasicInput from "../../../../components/BasicInput"
import Form from "../../../../components/Form"
import FirstTM from "../../../../components/Form/Validations/FirstTM"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledBlackText, StyledGreyText} from "../../../../components/Texts/styles"
import CalculateButton from "../../../../components/CalculateButton"
import {useTranslation} from "react-i18next"
import {useStore} from "../../../../store"
import {GestationalActionKind} from "../../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const First = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(FirstTM),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (ga: string, lmp: string, eed: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_FIRST,
      payload: {
        ...state.gestationalValues.first,
        ga: ga,
        lmp: lmp,
        eed: eed,
      },
    })
  }
  // El button deberia hacer un submit, averiguar
  return (
    <StyledFirst>
      <DropDownHeader
        name={t("gestational.dating.first.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}></DropDownHeader>

      {active && (
        <DropDownBody white nested>
          <Form handler={handleSubmit(submitHandler)}>
            <InColumnLabel>
              <BasicLabel name="dateUltrasound">
                {t("gestational.dating.first.dateInput")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                length={96}
                name="dateUltrasound"
                type="date"
                value={state.gestationalValues.first.dateUltrasound}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_FIRST,
                    payload: {
                      ...state.gestationalValues.first,
                      dateUltrasound: value,
                    },
                  })
                }
              />
            </InColumnLabel>
            <InColumnLabel>
              <BasicLabel name="crownToRumpLength">
                {t("gestational.dating.first.lengthInput")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                length={96}
                name="crownToRumpLength"
                type="tel"
                value={state.gestationalValues.first.crownToRumpLength}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_FIRST,
                    payload: {
                      ...state.gestationalValues.first,
                      crownToRumpLength: value,
                    },
                  })
                }
              />
            </InColumnLabel>

            <CalculateButton />

            <BasicOutput value={state.gestationalValues.first.ga} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.first.gaOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.first.egOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.first.lmp} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.first.lpmOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.first.furOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.first.eed} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.first.eddOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.first.fppOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>
          </Form>
        </DropDownBody>
      )}
    </StyledFirst>
  )
}

export default First
