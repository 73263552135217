import React from "react"
import {StyledClearValues} from "./styles"
import {useTranslation} from "react-i18next"

export type ClearValuesProps = {
  onClick: () => void
}

const ClearValues = ({onClick}: ClearValuesProps): JSX.Element => {
  const {t} = useTranslation("translation")
  return (
    <StyledClearValues onClick={onClick}>
      {t("buttons.clearValues")}
    </StyledClearValues>
  )
}

export default ClearValues
