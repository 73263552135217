import React from "react"
import {StyledLine} from "./styles"

type LineProps = {
  top?: number
  bottom?: number
  width?: number
  white?: boolean
}

const Line = ({top = 0, bottom = 0, width = 100, white = false}: LineProps) => (
  <StyledLine top={top} bottom={bottom} width={width} white={white} />
)

export default Line
