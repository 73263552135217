import {useTranslation} from "react-i18next"

const Select = (on: string): [{[key: string]: number}] => {
  const {t} = useTranslation("translation")
  return Object(t(`select.${on}`, {returnObjects: true}))
}

export const BladderSelect = (): [{[key: string]: number}] => Select("bladderSelect")

export const ParitySelect = (): [{[key: string]: number}] => Select("paritySelect")

export const EthnicGroupSelect = (): [{[key: string]: number}] =>
  Select("ethhnicGroupSelect")

export const ConfirmationSelect = (): [{[key: string]: number}] =>
  Select("confirmationSelect")
