import styled, {css} from "styled-components"

export const StyledLine = styled.hr<StyledLineProps>(
  ({top, bottom, width, white}) => css`
    margin-top: ${top}px;
    margin-bottom: ${bottom}px;
    width: ${width}%;
    ${white ? "border: 1px solid #EDEDED;" : ""}
  `
)

type StyledLineProps = {
  readonly top: number
  readonly bottom: number
  readonly width: number
  readonly white: boolean
}
