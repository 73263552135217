import React, {useState} from "react"
import {StyledMultiplePercentile, StyledBodyContainer} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import GenderRadio, {genders} from "../../../components/GenderRadio"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import {StyledGreyText} from "../../../components/Texts/styles"
import Form from "../../../components/Form"
import WeeksDaysInputs from "../../../components/WeeksDaysInputs"
import MultiplePercentileSchema from "../../../components/Form/Validations/MultiplePercentile"
import Line from "../../../components/Line"
import {useStore} from "../../../store"
import {GestationalActionKind} from "../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const MultiplePercentile = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(MultiplePercentileSchema),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (twinA: string, twinB: string, discordance: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_MULTIPLE,
      payload: {
        ...state.gestationalValues.multiple,
        twinA: twinA,
        twinB: twinB,
        discordance: discordance,
      },
    })
  }

  return (
    <StyledMultiplePercentile>
      <DropDownHeader
        name={t("gestational.multiplePercentile.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody>
          <Form handler={handleSubmit(submitHandler)}>
            <StyledBodyContainer>
              <InColumnLabel>
                <BasicLabel name="time">
                  {t("gestational.multiplePercentile.ga")}
                </BasicLabel>

                <WeeksDaysInputs
                  errors={errors}
                  register={register}
                  weeks={state.gestationalValues.multiple.weeks}
                  setWeeks={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_MULTIPLE,
                      payload: {...state.gestationalValues.multiple, weeks: value},
                    })
                  }
                  days={state.gestationalValues.multiple.days}
                  setDays={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_MULTIPLE,
                      payload: {...state.gestationalValues.multiple, days: value},
                    })
                  }
                />
              </InColumnLabel>

              <Line bottom={25} top={10} />

              <InColumnLabel>
                <BasicLabel name="genderLarger">
                  {t("gestational.multiplePercentile.genderLargerLabel")}
                </BasicLabel>
                <GenderRadio
                  errors={errors}
                  register={register}
                  value={state.gestationalValues.multiple.genderLarger}
                  setValue={(v) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_MULTIPLE,
                      payload: {
                        ...state.gestationalValues.multiple,
                        genderLarger: v as genders,
                      },
                    })
                  }
                  label="genderLarger"
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="efwLarger">
                  {t("gestational.multiplePercentile.efwLarger")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="efwLarger"
                  type="tel"
                  value={state.gestationalValues.multiple.efwLarger}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_MULTIPLE,
                      payload: {
                        ...state.gestationalValues.multiple,
                        efwLarger: value,
                      },
                    })
                  }
                />
              </InColumnLabel>

              <Line bottom={25} top={10} />

              <InColumnLabel>
                <BasicLabel name="genderSmaller">
                  {t("gestational.multiplePercentile.genderSmallerLabel")}
                </BasicLabel>
                <GenderRadio
                  errors={errors}
                  register={register}
                  value={state.gestationalValues.multiple.genderSmaller}
                  setValue={(v) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_MULTIPLE,
                      payload: {
                        ...state.gestationalValues.multiple,
                        genderSmaller: v as genders,
                      },
                    })
                  }
                  label="genderSmaller"
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="efwSmaller">
                  {t("gestational.multiplePercentile.efwSmaller")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="efwSmaller"
                  type="tel"
                  value={state.gestationalValues.multiple.efwSmaller}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_MULTIPLE,
                      payload: {
                        ...state.gestationalValues.multiple,
                        efwSmaller: value,
                      },
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <StyledGreyText>
                  {t("gestational.multiplePercentile.twinA")}
                </StyledGreyText>
                <BasicOutput
                  value={state.gestationalValues.multiple.twinA}
                  length={100}
                  border
                />
              </InLineLabel>

              <InLineLabel>
                <StyledGreyText>
                  {t("gestational.multiplePercentile.twinB")}
                </StyledGreyText>
                <BasicOutput
                  value={state.gestationalValues.multiple.twinB}
                  length={100}
                  border
                />
              </InLineLabel>

              <InLineLabel>
                <StyledGreyText>
                  {t("gestational.multiplePercentile.discordance")}
                </StyledGreyText>
                <BasicOutput
                  value={state.gestationalValues.multiple.discordance}
                  length={100}
                  border
                />
              </InLineLabel>
            </StyledBodyContainer>
          </Form>
        </DropDownBody>
      )}
    </StyledMultiplePercentile>
  )
}

export default MultiplePercentile
