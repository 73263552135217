import {StyledUpArrow, StyledDownArrow, StyledBackArrow} from "./styles"

type ArrowProps = {
  white?: boolean
}

export const UpArrow = ({white = false}: ArrowProps) => {
  return <StyledUpArrow white={white} />
}

export const DownArrow = ({white = false}: ArrowProps) => {
  return <StyledDownArrow white={white} />
}

type BackArrowProps = {
  onClick: () => void
}

export const BackArrow = ({onClick}: BackArrowProps) => {
  return <StyledBackArrow onClick={onClick} />
}
