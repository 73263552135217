import React from "react"
import BasicLabel from "../../../components/BasicLabel"
import {InColumnLabel} from "../../../components/LabelContainer"
import Select from "../../../components/Select"
import {useTranslation} from "react-i18next"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"

type HalfSelectProps = {
  value: {[key: string]: number}
  setValue: (v: {[key: string]: number}) => void
  name: string
  data: [{[key: string]: number}]
  register: UseFormRegister<FieldValues>
  setRefValue: (
    name: string,
    value: number,
    config?: Record<string, unknown>
  ) => void
  errors?: FieldErrorsImpl<FieldValues>
}

const HalfSelect = ({
  errors,
  value,
  setValue,
  name,
  data,
  register,
  setRefValue,
}: HalfSelectProps): JSX.Element => {
  const {t} = useTranslation("translation")
  return (
    <InColumnLabel>
      <BasicLabel name={name}>{t(`preeclampsia.${name}`)}</BasicLabel>
      <Select
        errors={errors}
        setRefValue={setRefValue}
        name={name}
        data={data}
        value={value}
        setValue={(v) => setValue(v)}
        register={register}></Select>
    </InColumnLabel>
  )
}

export default HalfSelect
