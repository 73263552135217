import styled, {css} from "styled-components"

export const StyledRadio = styled.input(
  () => css`
    display: none;
    &:checked + span:after {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
  `
)

export const StyledLabel = styled.label(
  ({theme: {weights, typography}}) => css`
    font-style: normal;
    font-weight: ${weights.regular};
    font-size: ${typography.medium};
    line-height: 19px;
  `
)

export const StyledSpan = styled.span(
  ({theme: {colors}}) => css`
  display: inline-block; 
    position: relative;
    padding-left: 30px;
    &:before{
      ${sharedSpan}
      top: 0px;
      left: 0px;
      margin-right: 5px;
      width: 16px;
      height: 16px;
      border: 2px solid ${colors.radioButton};
      background: #fff;
    }
    &:after{
      ${sharedSpan}
      width: 10px;
      height: 10px;
      background: ${colors.radioButton};
      border-colort: ${colors.radioButton};
      top: 5px;
      left: 5px;
      opacity: 0;
      transform: scale(0,0); 
      transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
  }
  `
)

const sharedSpan = `
content: '';
display: block; 
position: absolute;
border-radius: 50%;
`
