import {side} from "../../modules/Hernia"

export type LHRInitStateType = {
  weeks: string
  days: string
  longitudinal: string
  transversal: string
  circumference: string
  lhrOutput: string
  percentageOutput: string
}

export const LHRInitState = {
  weeks: "",
  days: "",
  longitudinal: "",
  transversal: "",
  circumference: "",
  lhrOutput: "",
  percentageOutput: "",
}

export type POStInitStateType = {
  lungToHead: string
  liverUp: string
  side: string
  expectedSurvivalOutput: string
}

export const POStInitState = {
  lungToHead: "",
  liverUp: "",
  expectedSurvivalOutput: "",
}

export type HerniaInitStateType = {
  side: side
  lhr: LHRInitStateType
  pos: POStInitStateType
}

const herniaInintState = {
  herniaValues: {
    side: "left",
    lhr: LHRInitState,
    pos: POStInitState,
  },
}

export default herniaInintState
