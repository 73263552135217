import * as yup from "yup"

const CVTSchema = yup.object({
  weeks: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 14", (val) => Number(val) >= 14)
    .test("range", "Cant be higher than 44", (val) => Number(val) <= 44),
  days: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Can only be 6 or lower", (val) => Number(val) <= 6),
  donoHema: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 50", (val) => Number(val) > 50)
    .test("range", "Cant be higher than 100", (val) => Number(val) <= 100),
  desiredHema: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 10", (val) => Number(val) > 10)
    .test("range", "Cant be higher than 60", (val) => Number(val) <= 60),
  observedHema: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 5", (val) => Number(val) > 5)
    .test("range", "Cant be higher than 60", (val) => Number(val) <= 60),
})

export default CVTSchema
