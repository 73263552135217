import styled from "styled-components"
import {StyledInColumnContainer} from "../../../components/LabelContainer/styles"

export const StyledMultipleResults = styled.div`
  ${StyledInColumnContainer} {
    width: 95%;
  }
`

export const StyledGenericPI = styled.div`
  ${StyledInColumnContainer} {
    width: 90%;
  }
`

export const StyledBodyContainer = styled.div`
  padding-left: 23px;
`
