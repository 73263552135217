import styled, {css} from "styled-components"
import {ReactComponent as Gestational} from "../../assets/svgs/Gestational.svg"
import {ReactComponent as Anemia} from "../../assets/svgs/Anemia.svg"
import {ReactComponent as Doppler} from "../../assets/svgs/Doppler.svg"
import {ReactComponent as FG} from "../../assets/svgs/FG.svg"
import {ReactComponent as Hernia} from "../../assets/svgs/Hernia.svg"
import {ReactComponent as MT} from "../../assets/svgs/MT.svg"
import {ReactComponent as Preeclampsia} from "../../assets/svgs/Preeclampsia.svg"
import {ReactComponent as Cardiac} from "../../assets/svgs/Cardiac.svg"

export const StyledSvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledTextHeader = styled.p(
  ({theme: {colors, weights, typography}}) => css`
    text-transform: uppercase;
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    line-height: 26px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: ${colors.activeText};
    margin-bottom: 0px;
  `
)

const styles = `
z-index: 1;
margin: 0 !important;
padding: 0;
width: 50%;
`

export const StyledGestationalImage = styled(Gestational)`
  ${styles}
`

export const StyledAnemiaImage = styled(Anemia)`
  ${styles}
`
export const StyledDopplerImage = styled(Doppler)`
  ${styles}
`
export const StyledFGImage = styled(FG)`
  ${styles}
`
export const StyledHerniaImage = styled(Hernia)`
  ${styles}
`
export const StyledMTImage = styled(MT)`
  ${styles}
`

export const StyledCardiacImage = styled(Cardiac)`
  ${styles}
`
export const StyledPreeclampsiaImage = styled(Preeclampsia)`
  ${styles}
`
