import React, {useState} from "react"
import {
  StyledLHR,
  StyledBodyContainer,
  StyledGreyBackground,
  StyledTitle,
} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import Form from "../../../components/Form"
import LHRSchema from "../../../components/Form/Validations/LHR"
import WeeksDaysInputs from "../../../components/WeeksDaysInputs"
import {StyledBlackText} from "../../../components/Texts/styles"
import {useStore} from "../../../store"
import {HerniaActionKind} from "../../../store/Reducers/Modules/hernia"
import {LHRCalc} from "../../../utils/algorithms/hernia"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {LHRInitStateType} from "../../../store/Initials/hernia"

const LHR = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(LHRSchema),
  })
  const submitHandler = (values: FieldValues) => {
    LHRCalc({
      lhrValues: values as LHRInitStateType,
      dispatch: updateOutputs,
      side: state.herniaValues.side,
    })
  }

  const updateOutputs = (lhr: string, percentage: string) => {
    dispatch({
      action: HerniaActionKind.UPDATE_LHR,
      payload: {
        ...state.herniaValues.lhr,
        lhrOutput: lhr,
        percentageOutput: percentage,
      },
    })
  }

  return (
    <StyledLHR>
      <DropDownHeader
        nested
        name={t("hernia.lhr.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <>
          <StyledGreyBackground>{t("hernia.lhr.ldm")}</StyledGreyBackground>
          <DropDownBody white>
            <StyledTitle>
              {t(`hernia.lhr.title${state.herniaValues.side}`)}
            </StyledTitle>
            <Form handler={handleSubmit(submitHandler)}>
              <StyledBodyContainer>
                <InColumnLabel>
                  <BasicLabel name="time">{t("hernia.lhr.ga")}</BasicLabel>
                  <WeeksDaysInputs
                    errors={errors}
                    register={register}
                    weeks={state.herniaValues.lhr.weeks}
                    setWeeks={(value) =>
                      dispatch({
                        action: HerniaActionKind.UPDATE_LHR,
                        payload: {...state.herniaValues.lhr, weeks: value},
                      })
                    }
                    days={state.herniaValues.lhr.days}
                    setDays={(value) =>
                      dispatch({
                        action: HerniaActionKind.UPDATE_LHR,
                        payload: {...state.herniaValues.lhr, days: value},
                      })
                    }
                  />
                </InColumnLabel>

                <InColumnLabel>
                  <BasicLabel name="longitudinal">
                    {t("hernia.lhr.longitudinal")}
                  </BasicLabel>
                  <BasicInput
                    errors={errors}
                    register={register}
                    length={96}
                    name="longitudinal"
                    type="tel"
                    value={state.herniaValues.lhr.longitudinal}
                    setValue={(value) =>
                      dispatch({
                        action: HerniaActionKind.UPDATE_LHR,
                        payload: {...state.herniaValues.lhr, longitudinal: value},
                      })
                    }
                  />
                </InColumnLabel>

                <InColumnLabel>
                  <BasicLabel name="transversal">
                    {t("hernia.lhr.transversal")}
                  </BasicLabel>
                  <BasicInput
                    errors={errors}
                    register={register}
                    length={96}
                    name="transversal"
                    type="tel"
                    value={state.herniaValues.lhr.transversal}
                    setValue={(value) =>
                      dispatch({
                        action: HerniaActionKind.UPDATE_LHR,
                        payload: {...state.herniaValues.lhr, transversal: value},
                      })
                    }
                  />
                </InColumnLabel>

                <InColumnLabel>
                  <BasicLabel name="circumference">
                    {t("hernia.lhr.circumference")}
                  </BasicLabel>
                  <BasicInput
                    errors={errors}
                    register={register}
                    length={96}
                    name="circumference"
                    type="tel"
                    value={state.herniaValues.lhr.circumference}
                    setValue={(value) =>
                      dispatch({
                        action: HerniaActionKind.UPDATE_LHR,
                        payload: {...state.herniaValues.lhr, circumference: value},
                      })
                    }
                  />
                </InColumnLabel>

                <CalculateButton />
                <InLineLabel>
                  <StyledBlackText>{t("hernia.lhr.lhrOutput")}</StyledBlackText>
                  <BasicOutput
                    value={state.herniaValues.lhr.lhrOutput}
                    length={100}
                  />
                </InLineLabel>

                <InLineLabel>
                  <StyledBlackText>
                    {t("hernia.lhr.percentageOutput")}
                  </StyledBlackText>
                  <BasicOutput
                    value={state.herniaValues.lhr.percentageOutput}
                    length={100}
                  />
                </InLineLabel>
              </StyledBodyContainer>
            </Form>
          </DropDownBody>
        </>
      )}
    </StyledLHR>
  )
}

export default LHR
