import styled, {css} from "styled-components"

export const StyledDiagnosticAlgorithm = styled.div(
  ({theme: {colors}}) => css`
    display: flex;
    flex-direction: column;
    margin: 2%;
    background: ${colors.whiteIshBackground};
    border-radius: 8px;
    padding: 15px;
  `
)

export const StyledHeader = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    line-height: 23px;
    text-align: center;
  `
)
