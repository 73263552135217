import React from "react"
import {StyledPreeclampsia, StyledHeader} from "./styles"
import {useTranslation} from "react-i18next"
import Form from "../../components/Form"
import PreeclamsiaSchema from "../../components/Form/Validations/Preeclampsia"
import CalculateButton from "../../components/CalculateButton"
import BasicOutput from "../../components/BasicOutput"
import EpidemiologicData from "./EpidemiologicData"
import BiometricData from "./BiometricData"
import FTD from "./FTD"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {
  preeclampsiaCalc,
  preeclampsiaValuesType,
} from "../../utils/algorithms/preeclampsia"
import {useStore} from "../../store"
import {PreeclampsiaActionKind} from "../../store/Reducers/Modules/preeclampsia"
import ImageHeader from "../../components/ImageHeader"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"

const Preeclampsia = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(PreeclamsiaSchema),
  })
  const submitHandler = (values: FieldValues) => {
    preeclampsiaCalc({
      preeclampsiaValues: values as preeclampsiaValuesType,
      dispatch: updateOutputs,
    })
  }

  const updateOutputs = (output: string) => {
    dispatch({
      action: PreeclampsiaActionKind.UPDATE_OUTPUT,
      payload: output,
    })
  }

  return (
    <>
      <ImageHeader module={StateModuleKind.PREECLAMPSIA1T} />
      <StyledPreeclampsia>
        <StyledHeader>{t("preeclampsia.header")}</StyledHeader>

        <Form handler={handleSubmit(submitHandler)}>
          <EpidemiologicData
            register={register}
            setRefValue={setValue}
            errors={errors}
          />

          <BiometricData register={register} errors={errors} />

          <FTD register={register} errors={errors} />

          <CalculateButton />

          <BasicOutput value={state.preeclampsiaValues.output} length={50}>
            {t("preeclampsia.output")}
          </BasicOutput>
        </Form>
      </StyledPreeclampsia>
    </>
  )
}

export default Preeclampsia
