import styled, {css} from "styled-components"

export const StyledColumn = styled.div<ColumnProps>(
  ({left}) => css`
    position: absolute;
    background: #e3e3e3;
    width: 49%;
    height: 100%;
    ${left ? "left: 0;" : "right: 0;"}
    top: 0;

    display: flex;
    flex-flow: column;
    gap: 70px;
  `
)

interface ColumnProps {
  readonly left: boolean
}

export const StyledHeader = styled.div`
  height: 50px;
  background: #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledTextColumn = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 50px;
  * {
    height: 50px;
  }
`
