import {stateType} from "../.."

export enum GestationalActionKind {
  UPDATE_LMP = "UPDATE_LMP",
  UPDATE_FIRST = "UPDATE_FIRST",
  UPDATE_SECOND = "UPDATE_SECOND",
  UPDATE_THIRD = "UPDATE_THIRD",
  UPDATE_DBP = "UPDATE_DBP",
  UPDATE_PA = "UPDATE_PA",
  UPDATE_GROWTH = "UPDATE_GROWTH",
  UPDATE_UNIQUE = "UPDATE_UNIQUE",
  UPDATE_MULTIPLE = "UPDATE_MULTIPLE",
}

interface GestationalModule {
  action: GestationalActionKind
  payload: string | {[key: string]: string}
}

export const gestationalReducer = (
  state: stateType,
  {action, payload}: GestationalModule
) => {
  switch (action) {
    case GestationalActionKind.UPDATE_LMP:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          lmp: payload,
        },
      }
    case GestationalActionKind.UPDATE_FIRST:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          first: payload,
        },
      }
    case GestationalActionKind.UPDATE_SECOND:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          second: payload,
        },
      }
    case GestationalActionKind.UPDATE_THIRD:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          third: payload,
        },
      }
    case GestationalActionKind.UPDATE_DBP:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          dbp: payload,
        },
      }
    case GestationalActionKind.UPDATE_PA:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          pa: payload,
        },
      }

    case GestationalActionKind.UPDATE_GROWTH:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          growth: payload,
        },
      }
    case GestationalActionKind.UPDATE_UNIQUE:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          unique: payload,
        },
      }
    case GestationalActionKind.UPDATE_MULTIPLE:
      return {
        ...state,
        gestationalValues: {
          ...state.gestationalValues,
          multiple: payload,
        },
      }
    default:
      return state
  }
}
