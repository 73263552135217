import React, {useState} from "react"
import {StyledEstimatedFetalWeight} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import DBP from "./DBP"
import {useTranslation} from "react-i18next"
import PA from "./PA"

const EstimatedFetalWeight = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  return (
    <StyledEstimatedFetalWeight>
      <DropDownHeader
        name={t("gestational.estimateFetalWeight.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody>
          <DBP />
          <PA />
        </DropDownBody>
      )}
    </StyledEstimatedFetalWeight>
  )
}

export default EstimatedFetalWeight
