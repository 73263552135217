import React, {useEffect, useState} from "react"
import {StyledPOS, StyledBodyContainer, StyledTitle} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import Form from "../../../components/Form"
import POSSchema from "../../../components/Form/Validations/POS"
import {StyledBlackText} from "../../../components/Texts/styles"
import {useStore} from "../../../store"
import {HerniaActionKind} from "../../../store/Reducers/Modules/hernia"
import {POSCalc} from "../../../utils/algorithms/hernia"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {POStInitStateType} from "../../../store/Initials/hernia"

const POS = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(POSSchema),
  })

  const submitHandler = (values: FieldValues) => {
    POSCalc({
      posValues: values as POStInitStateType,
      dispatch: updateOutputs,
    })
  }

  const updateOutputs = (expectedSurvival: string) => {
    dispatch({
      action: HerniaActionKind.UPDATE_POS,
      payload: {...state.herniaValues.pos, expectedSurvivalOutput: expectedSurvival},
    })
  }

  useEffect(() => {
    setValue("side", state.herniaValues.side)
  }, [state.herniaValues.side])

  return (
    <StyledPOS>
      <DropDownHeader
        nested
        name={t("hernia.pos.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody white>
          <StyledTitle>
            {t(`hernia.pos.title${state.herniaValues.side}`)}
          </StyledTitle>

          <Form handler={handleSubmit(submitHandler)}>
            <StyledBodyContainer>
              <InColumnLabel>
                <BasicLabel name="lungToHead">
                  {t("hernia.pos.lungToHead")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="lungToHead"
                  type="tel"
                  value={state.herniaValues.pos.lungToHead}
                  setValue={(value) =>
                    dispatch({
                      action: HerniaActionKind.UPDATE_POS,
                      payload: {...state.herniaValues.pos, lungToHead: value},
                    })
                  }
                />
              </InColumnLabel>

              {state.herniaValues.side == "left" && (
                <InColumnLabel>
                  <BasicLabel name="liverUp">{t("hernia.pos.liverUp")}</BasicLabel>
                  <BasicInput
                    errors={errors}
                    register={register}
                    length={96}
                    name="liverUp"
                    type="tel"
                    value={state.herniaValues.pos.liverUp}
                    setValue={(value) =>
                      dispatch({
                        action: HerniaActionKind.UPDATE_POS,
                        payload: {...state.herniaValues.pos, liverUp: value},
                      })
                    }
                  />
                </InColumnLabel>
              )}

              <CalculateButton />
              <InLineLabel>
                <StyledBlackText>
                  {t("hernia.pos.expectedSurvivalOutput")}
                </StyledBlackText>
                <BasicOutput
                  value={state.herniaValues.pos.expectedSurvivalOutput}
                  length={100}
                />
              </InLineLabel>
            </StyledBodyContainer>
          </Form>
        </DropDownBody>
      )}
    </StyledPOS>
  )
}

export default POS
