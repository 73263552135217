import styled, {css} from "styled-components"

export const StyledCalculateButton = styled.button<CalcultateButtonProps>(
  ({theme: {colors, weights, typography}, length}) => css`
    width: ${length}%;
    margin 2%;
    height: 40px;
    align-self: center;
    border: none;
    background: ${colors.buttonActiveBackground};
    color: ${colors.activeText};
    border-radius: 72px;
    font-weight: ${weights.semiBold};
    font-size: ${typography.medium};
    cursor: pointer;
  `
)

interface CalcultateButtonProps {
  readonly length: number
}
