import styled, {css} from "styled-components"

export const StyledDropDownHeader = styled.div<HeaderProps>(
  ({theme: {colors}, active}) => css`
    background: ${colors.dropdownHeaderBackground};
    width: 96%;
    margin-left: 2%;
    height: 58px;
    border-radius: ${active ? "8px 8px 0px 0px" : "8px 8px 8px 8px"};
    border: 1px solid ${colors.dropdownHeaderBackground};
    position: relative;
    margin-top: 24px;
  `
)

interface HeaderProps {
  readonly active: boolean
}

export const StyledSpan = styled.span`
  position: absolute;
  top: 32%;
  right: 34px;
`

export const StyledP = styled.p<StyledPProps>(
  ({theme: {colors, weights, typography}, nested}) => css`
    color: ${colors.activeText};
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    margin-top: 17px;
    ${nested ? "text-align: center;" : "padding-left: 15px;"}
  `
)

interface StyledPProps {
  readonly nested?: boolean
}
