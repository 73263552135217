import {stateType} from "../.."

export enum StateModuleKind {
  INITIAL = "Initial",
  GESTATIONAL = "Gestational",
  HERNIA = "Hernia",
  DOPPLER = "Doppler",
  MONOCHRONICTWINS = "Monochorionic Twins",
  PREECLAMPSIA1T = "Preeclampsia1T",
  ANEMIA = "Anemia",
  FETALGROWTH = "FetalGrowth",
  REFERENCES = "References",
}

export enum StateActionKind {
  CHANGE_MODULE = "CHANGE_MODULE",
}

interface StateModule {
  payload: StateModuleKind
  action: StateActionKind
}

export const menuReducer = (state: stateType, {payload, action}: StateModule) => {
  switch (action) {
    case StateActionKind.CHANGE_MODULE:
      return {...state, currentModule: payload}
    default:
      return state
  }
}
