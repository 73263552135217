import styled, {css} from "styled-components"
import {ReactComponent as BackArrow} from "../../assets/svgs/back_arrow.svg"

export const StyledDownArrow = styled.i<ArrowProps>(
  ({white}) => css`
    float: right;
    margin-right: 5%;
    margin-top: 3px;
    border: solid ${white ? "white" : "black"};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  `
)

export const StyledUpArrow = styled.i<ArrowProps>(
  ({white}) => css`
    float: right;
    margin-right: 5%;
    margin-top: 7px;
    border: solid ${white ? "white" : "black"};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  `
)

type ArrowProps = {
  readonly white?: boolean
}

export const StyledBackArrow = styled(BackArrow)`
  z-index: 1;
`
