import React from "react"
import ImageHeader from "../../components/ImageHeader"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"
import CerebralDoppler from "./CerebralDoppler"
import CVT from "./CVT"
import {StyledAnemia} from "./styles"

const Anemia = (): JSX.Element => {
  return (
    <StyledAnemia>
      <ImageHeader module={StateModuleKind.ANEMIA} />
      <CerebralDoppler />
      <CVT />
    </StyledAnemia>
  )
}

export default Anemia
