import {stateType} from "../.."

export enum AnemiaActionKind {
  UPDATE_CEREBRAL_DOPPLER = "UPDATE_CEREBRAL_DOPPLER",
  UPDATE_CVT = "UPDATE_CVT",
}

interface AnemiaModule {
  action: AnemiaActionKind
  payload: string | {[key: string]: string}
}

export const anemiaReducer = (state: stateType, {action, payload}: AnemiaModule) => {
  switch (action) {
    case AnemiaActionKind.UPDATE_CEREBRAL_DOPPLER:
      return {
        ...state,
        anemiaValues: {...state.anemiaValues, cerebralDoppler: payload},
      }
    case AnemiaActionKind.UPDATE_CVT:
      return {
        ...state,
        anemiaValues: {...state.anemiaValues, cvt: payload},
      }
    default:
      return state
  }
}
