import {yupResolver} from "@hookform/resolvers/yup"
import React, {useEffect, useState} from "react"
import {FieldErrorsImpl, FieldValues, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import BasicInput from "../../../components/BasicInput"
import BasicLabel from "../../../components/BasicLabel"
import BasicOutput from "../../../components/BasicOutput"
import CalculateButton from "../../../components/CalculateButton"
import DropDownBody from "../../../components/DropDown/Body"
import DropDownHeader from "../../../components/DropDown/Header"
import Form from "../../../components/Form"
import TEISchema from "../../../components/Form/Validations/TEI"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import {useStore} from "../../../store"
import {TEIInitStateType} from "../../../store/Initials/doppler"
import {DopplerActionKind} from "../../../store/Reducers/Modules/doppler"
import {calcTEI} from "../../../utils/algorithms/doppler"
import {StyledConfinedSpace} from "../styles"

type TEIProps = {
  ManageGAErrors: (errors: FieldErrorsImpl<FieldValues> | undefined) => void
}

const Tei = ({ManageGAErrors}: TEIProps) => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(TEISchema),
  })

  const submitHandler = (values: FieldValues) => {
    ManageGAErrors(undefined)
    calcTEI({teiValues: values as TEIInitStateType, dispatch: updateOutputs})
  }
  const errorHandler = (errors: FieldErrorsImpl<FieldValues>) => {
    ManageGAErrors(errors)
  }

  const updateOutputs = (teiIndex: string, percentil: string, results: string) => {
    dispatch({
      action: DopplerActionKind.UPDATE_TEI,
      payload: {
        ...state.dopplerValues.tei,
        teiIndex: teiIndex,
        percentil: percentil,
        results: results,
      },
    })
  }

  useEffect(() => {
    setValue("weeks", state.dopplerValues.weeks)
  }, [state.dopplerValues.weeks])

  useEffect(() => {
    setValue("days", state.dopplerValues.days)
  }, [state.dopplerValues.days])

  return (
    <>
      <DropDownHeader
        name={t("doppler.tei.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}
      />

      {active && (
        <StyledConfinedSpace>
          <DropDownBody nested white>
            <Form handler={handleSubmit(submitHandler, errorHandler)}>
              <InColumnLabel>
                <BasicLabel name="ict">{t("doppler.tei.ict")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="ict"
                  type="tel"
                  value={state.dopplerValues.tei.ict}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_TEI,
                      payload: {...state.dopplerValues.tei, ict: v},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="irt">{t("doppler.tei.irt")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="irt"
                  type="tel"
                  value={state.dopplerValues.tei.irt}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_TEI,
                      payload: {...state.dopplerValues.tei, irt: v},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="et">{t("doppler.tei.et")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="et"
                  type="tel"
                  value={state.dopplerValues.tei.et}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_TEI,
                      payload: {...state.dopplerValues.tei, et: v},
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <BasicLabel name="teiIndex">{t("doppler.tei.teiIndex")}</BasicLabel>
                <BasicOutput value={state.dopplerValues.tei.teiIndex} length={100} />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="percentil">
                  {t("doppler.tei.percentil")}
                </BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.tei.percentil}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="results">{t("doppler.tei.results")}</BasicLabel>
                <BasicOutput value={state.dopplerValues.tei.results} length={100} />
              </InLineLabel>
            </Form>
          </DropDownBody>
        </StyledConfinedSpace>
      )}
    </>
  )
}

export default Tei
