import styled, {css} from "styled-components"

export const StyledReferences = styled.div(
  ({theme: {colors}}) => css`
    padding: 3%;
    margin: 3%;
    background: ${colors.whiteIshBackground};
    border-radius: 8px;
    padding-bottom: 20px;
  `
)

export const StyledTitle = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.bold};
    font-size: ${typography.intermedium};
    line-height: 21px;
    margin-top: 25px;
  `
)

export const StyledRefTitle = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    line-height: 26px;
    text-align: center;
    margin-top: 25px;
    text-transform: uppercase;
  `
)

export const StyledOrderedList = styled.ol``

export const StyledText = styled.li(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.weak};
    font-size: ${typography.medium};
    line-height: 21px;
    margin-top: 25px;
  `
)
