import * as yup from "yup"

const TwinsAlgorithmSchema = yup.object({
  twinsGender: yup.string().nullable().required("Required"),
  maximumVerticalPocketOne: yup.string().required("Required"),
  bladderOne: yup.string().required("Required"),
  estimatedFetalWeightOne: yup.string().required("Required"),
  abdominalCircumferenceOne: yup.string().required("Required"),
  peakSystolicVelocityOne: yup.string().required("Required"),

  maximumVerticalPocketTwo: yup.string().required("Required"),
  bladderTwo: yup.string().required("Required"),
  estimatedFetalWeightTwo: yup.string().required("Required"),
  abdominalCircumferenceTwo: yup.string().required("Required"),
  peakSystolicVelocityTwo: yup.string().required("Required"),
})

export default TwinsAlgorithmSchema
