import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import BasicInput from "../../../components/BasicInput"
import BasicOutput from "../../../components/BasicOutput"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import {StyledBodyContainer, StyledGenericPI} from "./styles"
import CalculateButton from "../../../components/CalculateButton"
import {useStore} from "../../../store"
import Form from "../../../components/Form"
import GenericPISchema from "../../../components/Form/Validations/GenericPI"
import {FieldErrorsImpl, FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {GenericCalcProps} from "../../../utils/algorithms/doppler"
import {GenericPIInitStateType} from "../../../store/Initials/doppler"

type GenericPIProps = {
  name: string
  valueObj: {[key: string]: string}
  updateAction: string
  calc: ({genericValues, dispatch}: GenericCalcProps) => void
  ManageGAErrors: (errors: FieldErrorsImpl<FieldValues> | undefined) => void
}

const GenericPI = ({
  name,
  valueObj,
  updateAction,
  calc,
  ManageGAErrors,
}: GenericPIProps) => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(GenericPISchema),
  })

  const submitHandler = (values: FieldValues) => {
    ManageGAErrors(undefined)
    calc({genericValues: values as GenericPIInitStateType, dispatch: updateOutputs})
  }
  const errorHandler = (errors: FieldErrorsImpl<FieldValues>) => {
    ManageGAErrors(errors)
  }

  const updateOutputs = (percentil: string, results: string) => {
    dispatch({
      action: updateAction,
      payload: {
        ...valueObj,
        percentil: percentil,
        result: results,
      },
    })
  }

  useEffect(() => {
    setValue("weeks", state.dopplerValues.weeks)
  }, [state.dopplerValues.weeks])

  useEffect(() => {
    setValue("days", state.dopplerValues.days)
  }, [state.dopplerValues.days])

  return (
    <StyledGenericPI>
      <DropDownHeader
        name={name}
        nested
        active={active}
        toggle={() => setActive(!active)}
      />
      {active && (
        <DropDownBody nested white>
          <Form handler={handleSubmit(submitHandler, errorHandler)}>
            <StyledBodyContainer>
              <InColumnLabel>
                <BasicLabel name="pulsabilityIndex">
                  {t("doppler.genericPI.pulsabilityIndexLabel")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="pulsabilityIndex"
                  type="tel"
                  length={100}
                  value={valueObj.pulsabilityIndex}
                  setValue={(v) =>
                    dispatch({
                      action: updateAction,
                      payload: {...valueObj, pulsabilityIndex: v},
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton length={87} />

              <InColumnLabel>
                <InLineLabel>
                  <BasicLabel name="percentil">
                    {t("doppler.genericPI.percentil")}
                  </BasicLabel>
                  <BasicOutput length={90} value={valueObj.percentil} />
                </InLineLabel>
                <InLineLabel>
                  <BasicLabel name="result">
                    {t("doppler.genericPI.results")}
                  </BasicLabel>
                  <BasicOutput length={90} value={valueObj.result} />
                </InLineLabel>
              </InColumnLabel>
            </StyledBodyContainer>
          </Form>
        </DropDownBody>
      )}
    </StyledGenericPI>
  )
}

export default GenericPI
