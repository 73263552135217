import styled, {css} from "styled-components"

export const StyledGender = styled.div(
  () => css`
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 30px;
    align-self: flex-start;
  `
)

export const StyledErrorMessage = styled.p(
  ({theme: {colors}}) => css`
    color: ${colors.errorText};
    position: absolute;
    bottom: -20px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 12px;
    left: 0px;
  `
)

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  margin-bottom: 15px;
`
