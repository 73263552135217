import React, {useState} from "react"
import {StyledPA} from "./styles"
import DropDownHeader from "../../../../components/DropDown/Header"
import DropDownBody from "../../../../components/DropDown/Body"
import {InColumnLabel, InLineLabel} from "../../../../components/LabelContainer"
import BasicInput from "../../../../components/BasicInput"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledGreyText} from "../../../../components/Texts/styles"
import BasicLabel from "../../../../components/BasicLabel"
import Form from "../../../../components/Form"
import PASchema from "../../../../components/Form/Validations/PA"
import {useTranslation} from "react-i18next"
import CalculateButton from "../../../../components/CalculateButton"
import {useStore} from "../../../../store"
import {GestationalActionKind} from "../../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const PA = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(PASchema),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (weight: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_PA,
      payload: {
        ...state.gestationalValues.pa,
        weight: weight,
      },
    })
  }

  // Deberia tener un useEffect para que cada vez que confirmedLPM cambie se realize un submit del formulario
  return (
    <StyledPA>
      <DropDownHeader
        name={t("gestational.estimateFetalWeight.pa.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}></DropDownHeader>

      {active && (
        <DropDownBody white nested>
          <Form handler={handleSubmit(submitHandler)}>
            <InLineLabel>
              <InColumnLabel>
                <BasicLabel name="pa">
                  {t("gestational.estimateFetalWeight.pa.pa")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={90}
                  name="pa"
                  type="tel"
                  value={state.gestationalValues.pa.pa}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_PA,
                      payload: {...state.gestationalValues.pa, pa: value},
                    })
                  }
                />
              </InColumnLabel>
              <InColumnLabel>
                <BasicLabel name="lf">
                  {t("gestational.estimateFetalWeight.pa.lf")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={90}
                  name="lf"
                  type="tel"
                  value={state.gestationalValues.pa.lf}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_PA,
                      payload: {...state.gestationalValues.pa, lf: value},
                    })
                  }
                />
              </InColumnLabel>
            </InLineLabel>

            <CalculateButton />

            {/* Uso el InlineLabel en lugar de mandarle el styled grey text como children al output por motivos de estilos*/}
            <InLineLabel>
              <StyledGreyText>
                {t("gestational.estimateFetalWeight.pa.weightsOutput")}
              </StyledGreyText>
              <BasicOutput value={state.gestationalValues.pa.weight} length={100} />
            </InLineLabel>
          </Form>
        </DropDownBody>
      )}
    </StyledPA>
  )
}

export default PA
