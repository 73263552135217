import React from "react"
import {InLineLabel} from "../../../components/LabelContainer"
import {StyledNothing, StyledSectionHeader} from "../styles"
import {useTranslation} from "react-i18next"
import HalfSelect from "../HalfSelect"
import {
  ConfirmationSelect,
  EthnicGroupSelect,
  ParitySelect,
} from "../../../utils/SelectArrays"
import {useStore} from "../../../store"
import {PreeclampsiaActionKind} from "../../../store/Reducers/Modules/preeclampsia"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"

type EDProps = {
  register: UseFormRegister<FieldValues>
  setRefValue: (
    name: string,
    value: number,
    config?: Record<string, unknown>
  ) => void
  errors?: FieldErrorsImpl<FieldValues>
}

const EpidemiologicData = ({register, setRefValue, errors}: EDProps) => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  return (
    <>
      <StyledSectionHeader>
        {t("preeclampsia.epidemiologicHeader")}
      </StyledSectionHeader>

      <InLineLabel>
        <HalfSelect
          errors={errors}
          value={state.preeclampsiaValues.epidemiologic.parity}
          setRefValue={setRefValue}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_EPIDEMIOLOGIC,
              payload: {...state.preeclampsiaValues.epidemiologic, parity: v},
            })
          }
          name="parity"
          data={ParitySelect()}
          register={register}
        />
        <HalfSelect
          errors={errors}
          value={state.preeclampsiaValues.epidemiologic.previousPE}
          setRefValue={setRefValue}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_EPIDEMIOLOGIC,
              payload: {...state.preeclampsiaValues.epidemiologic, previousPE: v},
            })
          }
          name="previousPE"
          data={ConfirmationSelect()}
          register={register}
        />
      </InLineLabel>

      <InLineLabel>
        <HalfSelect
          errors={errors}
          value={state.preeclampsiaValues.epidemiologic.hypertension}
          setRefValue={setRefValue}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_EPIDEMIOLOGIC,
              payload: {...state.preeclampsiaValues.epidemiologic, hypertension: v},
            })
          }
          name="hypertension"
          data={ConfirmationSelect()}
          register={register}
        />
        <HalfSelect
          errors={errors}
          value={state.preeclampsiaValues.epidemiologic.renalDisease}
          setRefValue={setRefValue}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_EPIDEMIOLOGIC,
              payload: {...state.preeclampsiaValues.epidemiologic, renalDisease: v},
            })
          }
          name="renalDisease"
          data={ConfirmationSelect()}
          register={register}
        />
      </InLineLabel>

      <InLineLabel>
        <HalfSelect
          errors={errors}
          value={state.preeclampsiaValues.epidemiologic.ethnicGroup}
          setRefValue={setRefValue}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_EPIDEMIOLOGIC,
              payload: {...state.preeclampsiaValues.epidemiologic, ethnicGroup: v},
            })
          }
          name="ethnicGroup"
          data={EthnicGroupSelect()}
          register={register}
        />

        <StyledNothing />
      </InLineLabel>
    </>
  )
}

export default EpidemiologicData
