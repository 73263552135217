import React from "react"
import {StyledGestational} from "./styles"
import Dating from "./Dating"
import EstimatedFetalWeight from "./EstimatedFetalWeight"
import GrowthCentile from "./GrowthCentile"
import UniquePercentile from "./UniquePercentile"
import MultiplePercentile from "./MultiplePercentile"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"
import ImageHeader from "../../components/ImageHeader"

const Gestational = (): JSX.Element => {
  return (
    <StyledGestational>
      <ImageHeader module={StateModuleKind.GESTATIONAL} />
      <Dating />
      <EstimatedFetalWeight />
      <GrowthCentile />
      <UniquePercentile />
      <MultiplePercentile />
    </StyledGestational>
  )
}

export default Gestational
