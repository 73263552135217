import styled, {css} from "styled-components"

export const StyledInputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const StyledBasicInput = styled.input<InputProps>(
  ({theme: {colors}, length, disabled, errors}) => css`
    background-color: ${colors.inputBackground};
    border: 1px solid
      ${errors
        ? colors.inputErrorBorder
        : disabled
        ? colors.disabled
        : colors.inputBorder};
    height: 50px;
    // Longitudes provisorias hasta que pueda probar mas componentes juntos
    width: ${length}%;
    align-self: center;
    border-radius: 4px;
    position: relative;
    -webkit-appearance: none;
    margin-bottom: 0.5rem;

    ::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  `
)

export const StyledErrorMessage = styled.p(
  ({theme: {colors}}) => css`
    color: ${colors.errorText};
    position: absolute;
    bottom: -20px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 12px;
    left: 0px;
  `
)

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

interface InputProps {
  readonly length?: number
  ref: (e: HTMLInputElement) => void
  readonly errors?: boolean
}
