import styled, {css} from "styled-components"

export const StyledLeftRightTab = styled.div<StyledProps>(
  ({theme: {colors}, active}) => css`
    background: ${active ? colors.leftRightActiveBackground : "transparent"};
    ${active ? "box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);" : ""}
    width: 48%;
    height: 43px;
    border-radius: 38px;
    position: relative;
    margin: 4px;
  `
)

export const StyledP = styled.p<StyledProps>(
  ({theme: {colors, weights, typography}, active}) => css`
    color: ${active ? colors.leftRightActiveText : colors.leftRightPassiveText};
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    text-align: center;
    margin-top: 10px;
  `
)

interface StyledProps {
  readonly active?: boolean
}
