export type GenericPIInitStateType = {
  percentil: string
  results: string
  pulsabilityIndex: string
  weeks: string
  days: string
}

export const GenericPIInitState = {
  percentil: "",
  results: "",
  pulsabilityIndex: "",
  weeks: "",
  days: "",
}

export type CerebroPlacentalInitStateType = {
  picma: string
  piua: string
  cerebroRatio: string
  percentil: string
  results: string
  weeks: string
  days: string
}

export const CerebroPlacentalInitState = {
  picma: "",
  piua: "",
  cerebroRatio: "",
  percentil: "",
  results: "",
  weeks: "",
  days: "",
}

export type UterineInitStateType = {
  pirua: string
  pilua: string
  avrgpi: string
  percentil: string
  results: string
  weeks: string
  days: string
}

export const UterineInitState = {
  pirua: "",
  pilua: "",
  avrgpi: "",
  percentil: "",
  results: "",
  weeks: "",
  days: "",
}

export type TEIInitStateType = {
  ict: string
  irt: string
  et: string
  teiIndex: string
  percentil: string
  results: string
  weeks: string
  days: string
}

export const TEIInitState = {
  ict: "",
  irt: "",
  et: "",
  teiIndex: "",
  percentil: "",
  results: "",
  weeks: "",
  days: "",
}

export type DopplerInitStateType = {
  umbilical: GenericPIInitStateType
  middleCerebral: GenericPIInitStateType
  ductus: GenericPIInitStateType
  aortic: GenericPIInitStateType
  cerebroPlacental: CerebroPlacentalInitStateType
  uterine: UterineInitStateType
  tei: TEIInitStateType
  weeks: string
  days: string
}

const dopplerInitState = {
  dopplerValues: {
    weeks: "",
    days: "",
    umbilical: GenericPIInitState,
    middleCerebral: GenericPIInitState,
    ductus: GenericPIInitState,
    aortic: GenericPIInitState,
    cerebroPlacental: CerebroPlacentalInitState,
    uterine: UterineInitState,
    tei: TEIInitState,
  },
}

export default dopplerInitState

// export type UmbilicalInitStateType = {
//   percentilUmbilical: string
//   resultsUmbilical: string
//   pulsabilityIndexUmbilical: string
// }

// export const UmbilicalInitState = {
//   percentil: "",
//   results: "",
//   pulsabilityIndex: "",
// }

// export type MiddleCerebralInitStateType = {
//   percentil: string
//   results: string
//   pulsabilityIndex: string
// }

// export const MiddleCerebralInitState = {
//   percentil: "",
//   results: "",
//   pulsabilityIndex: "",
// }

// export type DuctusInitStateType = {
//   percentil: string
//   results: string
//   pulsabilityIndex: string
// }

// export const DuctusInitState = {
//   percentil: "",
//   results: "",
//   pulsabilityIndex: "",
// }

// export type AorticInitStateType = {
//   percentil: string
//   results: string
//   pulsabilityIndex: string
// }

// export const AorticInitState = {
//   percentil: "",
//   results: "",
//   pulsabilityIndex: "",
// }
