import * as yup from "yup"

const PreeclamsiaSchema = yup.object({
  parity: yup.string().required("Required"),
  previousPE: yup.string().required("Required"),
  hypertension: yup.string().required("Required"),
  renalDisease: yup.string().required("Required"),
  ethnicGroup: yup.string().required("Required"),

  age: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 12", (val) => Number(val) >= 12)
    .test("range", "Cant be higher than 55", (val) => Number(val) <= 55),

  height: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 100", (val) => Number(val) >= 100)
    .test("range", "Cant be higher than 200", (val) => Number(val) <= 200),

  weight: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 35", (val) => Number(val) >= 35)
    .test("range", "Cant be higher than 150", (val) => Number(val) <= 150),

  crl: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 45", (val) => Number(val) >= 45)
    .test("range", "Cant be higher than 85", (val) => Number(val) <= 85),

  systolicBP: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 60", (val) => Number(val) >= 60)
    .test("range", "Cant be higher than 200", (val) => Number(val) <= 200),

  diastolicBP: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 30", (val) => Number(val) >= 30)
    .test("range", "Cant be higher than 120", (val) => Number(val) <= 120),

  ipmaut: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+(\.[0-9]+)?$/, "Must be only digits")
    .test(
      "range",
      "Cant be lower than 0.3",
      (val) => parseFloat(val as string) >= 0.3
    )
    .test("range", "Cant be higher than 5", (val) => parseFloat(val as string) <= 5),
})

export default PreeclamsiaSchema
