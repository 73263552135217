import React from "react"
import {ThemeProvider, ThemeInterface} from "styled-components"
import {colors} from "./colors"
import {shadows} from "./shadows"
import {typography} from "./typography"
import {weights} from "./weights"

const BaseTheme: ThemeInterface = {
  colors: {...colors},
  shadows: {...shadows},
  typography: {...typography},
  weights: {...weights},
}

type Props = {
  children?: React.ReactNode
}

export const Theme: React.FC<Props> = ({children}: Props) => (
  <ThemeProvider theme={BaseTheme}>{children}</ThemeProvider>
)

export default Theme
