import * as yup from "yup"

const DBPSchema = yup.object({
  dbp: yup.string().required("Required"),
  hc: yup.string().required("Required"),
  ac: yup.string().required("Required"),
  fl: yup.string().required("Required"),
})

export default DBPSchema
