import styled, {css} from "styled-components"

const noBorders = css`
  margin: 0;
  padding: 0;
`

export const StyledBlackText = styled.p(
  ({theme: {colors}}) => css`
    color: ${colors.blackText};
    ${noBorders}
  `
)

export const StyledGreyText = styled.p(
  ({theme: {colors}}) => css`
    color: ${colors.greyText};
    ${noBorders}
  `
)
