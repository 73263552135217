import React, {useState} from "react"
import {StyledGrowthCentile, StyledBodyContainer} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"

import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import GenderRadio, {genders} from "../../../components/GenderRadio"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import {StyledGreyText} from "../../../components/Texts/styles"
import Form from "../../../components/Form"
import GrowthCentileSchema from "../../../components/Form/Validations/GrowthCentile"
import Line from "../../../components/Line"
import WeeksDaysInputs from "../../../components/WeeksDaysInputs"
import {useStore} from "../../../store"
import {GestationalActionKind} from "../../../store/Reducers/Modules/gestational"
import {yupResolver} from "@hookform/resolvers/yup"
import {FieldValues, useForm} from "react-hook-form"

const GrowthCentile = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(GrowthCentileSchema),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (output: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_GROWTH,
      payload: {
        ...state.gestationalValues.growth,
        output: output,
      },
    })
  }
  return (
    <StyledGrowthCentile>
      <DropDownHeader
        name={t("gestational.growthCentile.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody>
          <Form handler={handleSubmit(submitHandler)}>
            <StyledBodyContainer>
              <InColumnLabel>
                <BasicLabel name="genderGrowthCentile">
                  {t("gestational.growthCentile.genderLabel")}
                </BasicLabel>
                <GenderRadio
                  errors={errors}
                  register={register}
                  value={state.gestationalValues.growth.genderGrowthCentile}
                  setValue={(v) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {
                        ...state.gestationalValues.growth,
                        genderGrowthCentile: v as genders,
                      },
                    })
                  }
                  label="genderGrowthCentile"
                />
              </InColumnLabel>

              <Line bottom={20} />

              <InColumnLabel>
                <BasicLabel name="bdpFirst">
                  {t("gestational.growthCentile.BDPFirst")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="bdpFirst"
                  type="tel"
                  value={state.gestationalValues.growth.bdpFirst}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {...state.gestationalValues.growth, bdpFirst: value},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="time">
                  {t("gestational.growthCentile.GAFirst")}
                </BasicLabel>

                <WeeksDaysInputs
                  nameWeek="weeksFirst"
                  nameDay="daysFirst"
                  errors={errors}
                  register={register}
                  weeks={state.gestationalValues.growth.weeksFirst}
                  setWeeks={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {
                        ...state.gestationalValues.growth,
                        weeksFirst: value,
                      },
                    })
                  }
                  days={state.gestationalValues.growth.daysFirst}
                  setDays={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {...state.gestationalValues.growth, daysFirst: value},
                    })
                  }
                />
              </InColumnLabel>

              <Line bottom={20} />

              <InColumnLabel>
                <BasicLabel name="bdpSecond">
                  {t("gestational.growthCentile.BDPSecond")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="bdpSecond"
                  type="tel"
                  value={state.gestationalValues.growth.bdpSecond}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {...state.gestationalValues.growth, bdpSecond: value},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="time">
                  {t("gestational.growthCentile.GASecond")}
                </BasicLabel>

                <WeeksDaysInputs
                  nameWeek="weeksSecond"
                  nameDay="daysSecond"
                  errors={errors}
                  register={register}
                  weeks={state.gestationalValues.growth.weeksSecond}
                  setWeeks={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {
                        ...state.gestationalValues.growth,
                        weeksSecond: value,
                      },
                    })
                  }
                  days={state.gestationalValues.growth.daysSecond}
                  setDays={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_GROWTH,
                      payload: {
                        ...state.gestationalValues.growth,
                        daysSecond: value,
                      },
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <StyledGreyText>
                  {t("gestational.growthCentile.output")}
                </StyledGreyText>
                <BasicOutput
                  value={state.gestationalValues.growth.output}
                  length={100}
                  border
                />
              </InLineLabel>
            </StyledBodyContainer>
          </Form>
        </DropDownBody>
      )}
    </StyledGrowthCentile>
  )
}

export default GrowthCentile
