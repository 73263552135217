import * as yup from "yup"

const TEISchema = yup.object({
  weeks: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 20", (val) => Number(val) >= 20)
    .test("range", "Cant be higher than 44", (val) => Number(val) <= 44),
  days: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Can only be 6 or lower", (val) => Number(val) <= 6),
  ict: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 10", (val) => Number(val) >= 10)
    .test("range", "Cant be higher than 99", (val) => Number(val) <= 99),
  irt: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 10", (val) => Number(val) >= 10)
    .test("range", "Cant be higher than 99", (val) => Number(val) <= 200),
  et: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 99", (val) => Number(val) >= 99)
    .test("range", "Cant be higher than 199", (val) => Number(val) <= 200),
})

export default TEISchema
