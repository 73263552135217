import {genders} from "../../components/GenderRadio"

export type MonochronicTwinsInitStateType = {
  confirmedLMP: string
  gaByLMP: string
  twinsGender: genders
  maximumVerticalPocketOne: string
  bladderOne: {[key: string]: number}
  estimatedFetalWeightOne: string
  abdominalCircumferenceOne: string
  peakSystolicVelocityOne: string
  maximumVerticalPocketTwo: string
  bladderTwo: {[key: string]: number}
  estimatedFetalWeightTwo: string
  abdominalCircumferenceTwo: string
  peakSystolicVelocityTwo: string
  outputOne: string
  outputTwo: string
}

const monochronicTwinsInitState = {
  monochronicTwinsValues: {
    confirmedLMP: "",
    gaByLMP: "",
    twinsGender: "",
    maximumVerticalPocketOne: "",
    bladderOne: "",
    estimatedFetalWeightOne: "",
    abdominalCircumferenceOne: "",
    peakSystolicVelocityOne: "",
    maximumVerticalPocketTwo: "",
    bladderTwo: "",
    estimatedFetalWeightTwo: "",
    abdominalCircumferenceTwo: "",
    peakSystolicVelocityTwo: "",
    outputOne: "",
    outputTwo: "",
  },
}

export default monochronicTwinsInitState
