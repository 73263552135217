import React from "react"

import {StyledBasicLabel, StyledLabelContainer} from "./styles"

export type BasicLabelProps = {
  name: string
  children: React.ReactNode
  heavy?: boolean
  disabled?: boolean
}

const BasicLabel = ({
  name,
  children,
  heavy = false,
  disabled = false,
}: BasicLabelProps): JSX.Element => {
  return (
    <StyledLabelContainer>
      <StyledBasicLabel htmlFor={name} heavy={heavy} disabled={disabled}>
        {children}
      </StyledBasicLabel>
    </StyledLabelContainer>
  )
}

export default BasicLabel
