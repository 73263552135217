import styled, {css} from "styled-components"

export const StyledRecomendations = styled.div(
  ({theme: {colors}}) => css`
    margin: 2%;
    background: ${colors.outputBackground};
    border: 1px solid ${colors.primaryDropeddownBorder};
    border-radius: 8px;
    padding-bottom: 2%;
    padding-left: 2%;
  `
)

export const StyledHeader = styled.p(
  ({theme: {weights, typography}}) => css`
    font-size: ${typography.medium};
    letter-spacing: -0.02em;
    font-weight: ${weights.regular};
  `
)

export const StyledList = styled.li(
  ({theme: {weights, typography}}) => css`
    font-size: ${typography.small};
    letter-spacing: -0.02em;
    font-weight: ${weights.weak};
    padding: 1%;
  `
)

export const StyledUL = styled.ul``
