import styled, {css} from "styled-components"

export const StyledDropDownBody = styled.div<DropDownBodyProps>(
  ({theme: {colors}, white, nested}) => css`
    background: ${!white
      ? colors.primaryDropeddownBackground
      : colors.secondaryDropeddownBackground};
    border-radius: 0px 0px 8px 8px;
    border: ${!white
      ? "1px solid" + colors.primaryDropeddownBorder
      : "1px solid" + colors.secondaryDropeddownBorder};
    display: flex;
    width: 96%;
    margin-left: 2%;
    flex-direction: column;
    justify-content: center;
    ${nested
      ? "padding-bottom: 0px; padding-top: 20px; margin-bottom: 0px;"
      : "padding-bottom: 24px; padding-top: 0px; margin-bottom: 39px;"}
    // ${nested ? "padding-top: 24px;" : "padding-top: 0px;"}
    // ${nested ? "margin-bottom: 0px;" : "margin-bottom: 39px;"}

    * {
      align-self: center;
      // width: 96%;
    }
  `
)

interface DropDownBodyProps {
  readonly white?: boolean
  readonly nested?: boolean
}
