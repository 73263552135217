import styled, {css} from "styled-components"
import {StyledDownArrow} from "../Arrows/styles"

export const StyledSelectContainer = styled.div<StyledSelectContainerProps>`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
`

type StyledSelectContainerProps = {
  ref: React.RefObject<HTMLDivElement>
}

export const StyledSelect = styled.span(
  () => css`
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
  `
)

export const StyledOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
`

export const StyledErrorMessage = styled.p(
  ({theme: {colors}}) => css`
    color: ${colors.errorText};
    position: absolute;
    bottom: -30px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 12px;
    left: 0px;
  `
)

export const StyledOption = styled.div<StyledOptionProps>(
  ({theme: {colors}, selected, border, errors, zindex}) => css`
    background-color: ${
      selected ? colors.selectActiveBackground : colors.selectPassiveBackground
    };
    border: 1px solid ${errors ? colors.inputErrorBorder : colors.selectBorder};
    height: 50px;
    width: 100%;
    align-self: center;
    border-radius: ${
      (border == "top" && top) ||
      (border == "bottom" && bottom) ||
      (border == "both" && both) ||
      (border == "none" && none)
    };
    gap: 10px;
    display: flex;
    z-index: ${zindex};
    align-items: center;
    justify-content: space-between;
    padding-left 8px;
    padding-right: 8px;
    text-align: center;
    position: relative;
    word-wrap: break-word;
    ${StyledDownArrow} {
      margin-top: -5px;
    }
  `
)

export const StyledSelectLabel = styled.p(
  () => css`
    align-self: center;
  `
)

const top = "4px 4px 0 0"
const bottom = "0 0 4px 4px"
const both = "4px 4px 4px 4px"
const none = "0 0 0 0"

type StyledOptionProps = {
  readonly selected?: boolean
  readonly border?: "top" | "bottom" | "both" | "none"
  value?: {[key: string]: number} | null
  readonly errors?: boolean
  readonly zindex: number
}

export const StyledOptionValue = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: $ ${weights.weak};
    font-size: ${typography.medium};
    line-height: 19px;
  `
)
