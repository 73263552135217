import React, {useState} from "react"
import {StyledDoppler, StyledGAContainer} from "./styles"
import {useTranslation} from "react-i18next"
import Cerebro from "./Cerebro"
import Uterine from "./Uterine"
import Tei from "./TEI"
import {InColumnLabel} from "../../components/LabelContainer"
import BasicLabel from "../../components/BasicLabel"
import WeeksDaysInputs from "../../components/WeeksDaysInputs"
import ClearValues from "../../components/ClearValues"
import GenericPI from "./GenericPI"
import {useStore} from "../../store"
import {DopplerActionKind} from "../../store/Reducers/Modules/doppler"
import {FieldErrorsImpl, FieldValues, useForm} from "react-hook-form"
import {
  calcDuctus,
  calcIsthmus,
  calcMCA,
  calcUmmbilicalArtery,
} from "../../utils/algorithms/doppler"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"
import ImageHeader from "../../components/ImageHeader"

const Doppler = () => {
  const {t} = useTranslation("translation")
  const [errors, setErrors] = useState<FieldErrorsImpl<FieldValues> | undefined>(
    undefined
  )
  const {dispatch, state} = useStore()
  // este useForm esta unicamente para poder enviar uno al weeksDays
  const {register} = useForm()

  const clearValues = () => {
    dispatch({
      action: DopplerActionKind.UPDATE_WEEKS,
      payload: "",
    })
    dispatch({
      action: DopplerActionKind.UPDATE_DAYS,
      payload: "",
    })
  }

  const ManageGAErrors = (errors: FieldErrorsImpl<FieldValues> | undefined) => {
    setErrors(errors)
  }

  return (
    <StyledDoppler>
      <ImageHeader module={StateModuleKind.DOPPLER} />
      <StyledGAContainer>
        <InColumnLabel>
          <BasicLabel name="time">{t("doppler.multipleResults.ga")}</BasicLabel>
          <WeeksDaysInputs
            errors={errors}
            register={register}
            weeks={state.dopplerValues.weeks as string}
            setWeeks={(value) =>
              dispatch({action: DopplerActionKind.UPDATE_WEEKS, payload: value})
            }
            days={state.dopplerValues.days as string}
            setDays={(value) =>
              dispatch({action: DopplerActionKind.UPDATE_DAYS, payload: value})
            }
          />
        </InColumnLabel>
        <ClearValues onClick={clearValues} />
      </StyledGAContainer>

      <GenericPI
        ManageGAErrors={ManageGAErrors}
        calc={calcUmmbilicalArtery}
        name={t("doppler.umbilicalArtery")}
        valueObj={state.dopplerValues.umbilical}
        updateAction={DopplerActionKind.UPDATE_UMBILICAL}
      />
      <GenericPI
        ManageGAErrors={ManageGAErrors}
        calc={calcMCA}
        name={t("doppler.middleCerebralArtery")}
        valueObj={state.dopplerValues.middleCerebral}
        updateAction={DopplerActionKind.UPDATE_MIDDLE_CEREBRAL}
      />
      <Cerebro ManageGAErrors={ManageGAErrors} />
      <Uterine ManageGAErrors={ManageGAErrors} />
      <GenericPI
        ManageGAErrors={ManageGAErrors}
        calc={calcDuctus}
        name={t("doppler.ductusVenosus")}
        updateAction={DopplerActionKind.UPDATE_DUCTUS}
        valueObj={state.dopplerValues.ductus}
      />
      <GenericPI
        ManageGAErrors={ManageGAErrors}
        calc={calcIsthmus}
        name={t("doppler.aorticIsthmus")}
        updateAction={DopplerActionKind.UPDATE_AORTIC}
        valueObj={state.dopplerValues.aortic}
      />
      <Tei ManageGAErrors={ManageGAErrors} />
    </StyledDoppler>
  )
}

export default Doppler
