import * as yup from "yup"

const GrowthCentileSchema = yup.object({
  genderGrowthCentile: yup.string().nullable().required("Required"),
  bdpFirst: yup.string().required("Required"),
  weeksFirst: yup.string().required("Required"),
  daysFirst: yup.string().required("Required"),
  bdpSecond: yup.string().required("Required"),
  weeksSecond: yup.string().required("Required"),
  daysSecond: yup.string().required("Required"),
})

export default GrowthCentileSchema
