import React from "react"
import {StyledLeftRightTab, StyledP} from "./styles"

type LeftRightTabProps = {
  name: string
  active: boolean
  toggle: () => void
}

const LeftRightTab = ({name, active, toggle}: LeftRightTabProps): JSX.Element => {
  return (
    <StyledLeftRightTab onClick={toggle} active={active}>
      <StyledP active={active}>{name}</StyledP>
    </StyledLeftRightTab>
  )
}

export default LeftRightTab
