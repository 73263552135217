import {genders} from "../../components/GenderRadio"

export type LPMInitStateType = {
  confirmedLPM: string
  ga: string
  eed: string
}

export const LPMInitState = {
  confirmedLPM: "",
  ga: "",
  eed: "",
}

export type FirstInitStateType = {
  dateUltrasound: string
  crownToRumpLength: string
  ga: string
  lmp: string
  eed: string
}

export const FirstInitState = {
  dateUltrasound: "",
  crownToRumpLength: "",
  ga: "",
  lmp: "",
  eed: "",
}

export type SecondInitStateType = {
  biparietalDiameter: string
  dateUltrasound: string
  ga: string
  lmp: string
  fur: string
}

export const SecondInitState = {
  biparietalDiameter: "",
  dateUltrasound: "",
  ga: "",
  lmp: "",
  fur: "",
}

export type ThirdInitStateType = {
  headDiameter: string
  dateUltrasound: string
  ga: string
  lmp: string
  fur: string
}

export const ThirdInitState = {
  headDiameter: "",
  dateUltrasound: "",
  ga: "",
  lmp: "",
  fur: "",
}

export type DBPInitStateType = {
  dbp: string
  hc: string
  ac: string
  fl: string
  weight: string
}

export const DBPInitState = {
  dbp: "",
  hc: "",
  ac: "",
  fl: "",
  weight: "",
}

export type PAInitStateType = {
  pa: string
  lf: string
  weight: string
}

export const PAInitState = {
  pa: "",
  lf: "",
  weight: "",
}

export type GrowthCentileInitStateType = {
  genderGrowthCentile: genders
  bdpFirst: string
  weeksFirst: string
  daysFirst: string
  bdpSecond: string
  weeksSecond: string
  daysSecond: string
  output: string
}

export const GrowthCentileInitState = {
  genderGrowthCentile: "",
  bdpFirst: "",
  weeksFirst: "",
  daysFirst: "",
  bdpSecond: "",
  weeksSecond: "",
  daysSecond: "",
  output: "",
}

export type UniquePercentileInitStateType = {
  genderUnique: genders
  efw: string
  weeks: string
  days: string
  output: string
}

export const UniquePercentileInitState = {
  genderUnique: "",
  efw: "",
  weeks: "",
  days: "",
  output: "",
}

export type MultiplePercentileInitStateType = {
  genderLarger: genders
  genderSmaller: genders
  efwLarger: string
  efwSmaller: string
  weeks: string
  days: string
  twinA: string
  twinB: string
  discordance: string
}

export const MultiplePercentileInitState = {
  genderLarger: "",
  genderSmaller: "",
  efwLarger: "",
  efwSmaller: "",
  weeks: "",
  days: "",
  twinA: "",
  twinB: "",
  discordance: "",
}

export type GestationalInitStateType = {
  lmp: LPMInitStateType
  first: FirstInitStateType
  second: SecondInitStateType
  third: ThirdInitStateType
  dbp: DBPInitStateType
  pa: PAInitStateType
  growth: GrowthCentileInitStateType
  unique: UniquePercentileInitStateType
  multiple: MultiplePercentileInitStateType
}

const gestationalInitState = {
  gestationalValues: {
    lmp: LPMInitState,
    first: FirstInitState,
    second: SecondInitState,
    third: ThirdInitState,
    dbp: DBPInitState,
    pa: PAInitState,
    growth: GrowthCentileInitState,
    unique: UniquePercentileInitState,
    multiple: MultiplePercentileInitState,
  },
}

export default gestationalInitState
