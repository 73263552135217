import {stateType} from "../.."
import monochronicTwinsInitState from "../../Initials/monochronicTwins"

export enum MonochronicTwinsActionKind {
  UPDATE_CONFIRMED_LMP = "UPDATE_CONFIRMED_LMP",
  UPDATE_GA_BY_LMP = "UPDATE_GA_BY_LMP",
  UPDATE_TWINS_GENDER = "UPDATE_TWINS_GENDER",
  UPDATE_MVPO = "UPDATE_MVPO",
  UPDATE_MVPT = "UPDATE_MVPT",
  UPDATE_BLADDER_ONE = "UPDATE_BLADDER_ONE",
  UPDATE_BLADDER_TWO = "UPDATE_BLADDER_TWO",
  UPDATE_EFWO = "UPDATE_EFWO",
  UPDATE_EFWT = "UPDATE_EFWT",
  UPDATE_ABDOMINAL_ONE = "UPDATE_ABDOMINAL_ONE",
  UPDATE_ABDOMINAL_TWO = "UPDATE_ABDOMINAL_TWO",
  UPDATE_PSVO = "UPDATE_PSVO",
  UPDATE_PSVT = "UPDATE_PSVT",
  UPDATE_OUTPUT_ONE = "UPDATE_OUTPUT_ONE",
  UPDATE_OUTPUT_TWO = "UPDATE_OUTPUT_TWO",
  CLEAR_VALUES = "CLEAR_VALUES",
}

interface MonochronicTwinsModule {
  action: MonochronicTwinsActionKind
  payload: string | {[key: string]: string}
}

export const monochronicTwinsReducer = (
  state: stateType,
  {action, payload}: MonochronicTwinsModule
) => {
  switch (action) {
    case MonochronicTwinsActionKind.UPDATE_CONFIRMED_LMP:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          confirmedLMP: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_GA_BY_LMP:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          gaByLMP: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_TWINS_GENDER:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          twinsGender: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_MVPO:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          maximumVerticalPocketOne: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_MVPT:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          maximumVerticalPocketTwo: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_BLADDER_ONE:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          bladderOne: payload,
        },
      }

    case MonochronicTwinsActionKind.UPDATE_BLADDER_TWO:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          bladderTwo: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_EFWO:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          estimatedFetalWeightOne: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_EFWT:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          estimatedFetalWeightTwo: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_ABDOMINAL_ONE:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          abdominalCircumferenceOne: payload,
        },
      }

    case MonochronicTwinsActionKind.UPDATE_ABDOMINAL_TWO:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          abdominalCircumferenceTwo: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_PSVO:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          peakSystolicVelocityOne: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_PSVT:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          peakSystolicVelocityTwo: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_OUTPUT_ONE:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          outputOne: payload,
        },
      }
    case MonochronicTwinsActionKind.UPDATE_OUTPUT_TWO:
      return {
        ...state,
        monochronicTwinsValues: {
          ...state.monochronicTwinsValues,
          outputTwo: payload,
        },
      }
    case MonochronicTwinsActionKind.CLEAR_VALUES:
      return {
        ...state,
        monochronicTwinsValues: {
          ...monochronicTwinsInitState.monochronicTwinsValues,
          ...(payload as object),
        },
      }
    default:
      return state
  }
}
