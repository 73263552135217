import {createGlobalStyle} from "styled-components"
import Roboto from "./assets/fonts/Roboto-Regular.ttf"
import Roboto500Regular from "./assets/fonts/Roboto-Medium.ttf"
import Roboto700Regular from "./assets/fonts/Roboto-Bold.ttf"

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roboto400Regular';
    src: local('Roboto'), url(${Roboto}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto500Regular';
    src: local('Roboto'), url(${Roboto500Regular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto700Regular';
    src: local('Roboto'), url(${Roboto700Regular}) format('truetype');
    font-display: swap;
  }

  * {
    font-family: "Roboto400Regular";
  }

  body {
    max-width: ${
      /Android|iPhone|iPad/i.test(navigator.userAgent) ? "initial" : "40%"
    };
    margin: auto;
    padding-bottom: 50px;
    padding-top: 40px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: #212121;
  }
  
  body::-webkit-scrollbar {
    display: none;
  }
  
`
