import styled, {css} from "styled-components"

export const StyledClearValues = styled.p(
  ({theme: {colors, weights, typography}}) => css`
    background: inherit;
    border-radius: 8px;
    border: none;
    color: ${colors.clearValuesColor};
    font-weight: ${weights.semiBold};
    font-size: ${typography.medium};
    align-self: center;
    cursor: pointer;
  `
)
