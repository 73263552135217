import {stateType} from "../.."

export enum HerniaActionKind {
  UPDATE_SIDE = "UPDATE_SIDE",
  UPDATE_LHR = "UPDATE_LHR",
  UPDATE_POS = "UPDATE_POS",
}

interface HerniaModule {
  action: HerniaActionKind
  payload: string | {[key: string]: string}
}

export const herniaReducer = (state: stateType, {action, payload}: HerniaModule) => {
  switch (action) {
    case HerniaActionKind.UPDATE_SIDE:
      return {
        ...state,
        herniaValues: {
          lhr: {...state.herniaValues.lhr, lhrOutput: "", percentageOutput: ""},
          pos: {...state.herniaValues.pos, expectedSurvivalOutput: ""},
          side: payload,
        },
      }
    case HerniaActionKind.UPDATE_LHR:
      return {
        ...state,
        herniaValues: {...state.herniaValues, lhr: payload},
      }
    case HerniaActionKind.UPDATE_POS:
      return {
        ...state,
        herniaValues: {...state.herniaValues, pos: payload},
      }
    default:
      return state
  }
}
