import React, {useEffect, useState} from "react"
import {StyledButtonList, StyledMenu} from "./styles"
import BasicButton from "../../components/BasicButton"
import {useStore} from "../../store"
import {StateModuleKind, StateActionKind} from "../../store/Reducers/Modules/menu"
import Hernia from "../Hernia"
import Gestational from "../Gestational"
import MonochronicTwins from "../MonochronicTwins"
import Preeclampsia from "../Preeclampsia"
import Anemia from "../Anemia"
import FetalGrowth from "../FetalGrowth"
import References from "../References"
import Doppler from "../Doppler"
import ButtonsSvgs from "../../components/ButtonsSvgs"
import {BackArrow} from "../../components/Arrows"
import Drawer from "../../components/Drawer"

type buttonMapperType = {[key: string]: JSX.Element}

const buttonMapper: buttonMapperType = {
  [StateModuleKind.INITIAL]: <></>,
  [StateModuleKind.GESTATIONAL]: <Gestational />,
  [StateModuleKind.HERNIA]: <Hernia />,
  [StateModuleKind.DOPPLER]: <Doppler />,
  [StateModuleKind.MONOCHRONICTWINS]: <MonochronicTwins />,
  [StateModuleKind.PREECLAMPSIA1T]: <Preeclampsia />,
  [StateModuleKind.ANEMIA]: <Anemia />,
  [StateModuleKind.FETALGROWTH]: <FetalGrowth />,
  [StateModuleKind.REFERENCES]: <References />,
}

type buttonList = {
  [key: string]: boolean
}

const Menu = (): JSX.Element => {
  const falseArray: buttonList = Object.assign(
    {},
    ...Object.values(StateModuleKind).map((value) => ({[value]: false}))
  )

  const [buttons, setButtons] = useState(falseArray)
  const [drawer, setDrawer] = useState(false)
  const {dispatch, state} = useStore()

  const handleClick = (position: string) => {
    setButtons({...falseArray, [position]: !buttons[position]})
    if (buttons[position]) {
      position = StateModuleKind.INITIAL
    }
    dispatch({
      action: StateActionKind.CHANGE_MODULE,
      payload: position,
    })
  }

  const invalidButtons = [StateModuleKind.INITIAL, StateModuleKind.REFERENCES]

  const ButtonsList = Object.values(StateModuleKind).map((value, key) => {
    if (invalidButtons.includes(value)) {
      return null
    }
    return (
      <BasicButton
        key={key}
        active={buttons[value]}
        onClick={() => handleClick(value)}>
        <ButtonsSvgs button={value} />
        {value}
      </BasicButton>
    )
  })

  useEffect(() => {
    setDrawer(false)
  }, [state.currentModule])

  return (
    <StyledMenu>
      {state.currentModule == StateModuleKind.INITIAL ? (
        <>
          <Drawer
            isVisible={drawer}
            toggle={() => setDrawer(!drawer)}
            close={() => setDrawer(false)}
          />
          <StyledButtonList>{ButtonsList}</StyledButtonList>
        </>
      ) : (
        <>
          <BackArrow onClick={() => handleClick(StateModuleKind.INITIAL)} />
          {buttonMapper[state.currentModule]}
        </>
      )}
    </StyledMenu>
  )
}

export default Menu
