import React from "react"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"
import {
  StyledGestational,
  StyledHernia,
  StyledDoppler,
  StyledMT,
  StyledPreeclampsia,
  StyledAnemia,
  StyledFG,
} from "./styles"

type buttonMapperType = {[key: string]: JSX.Element}

const buttonMapper: buttonMapperType = {
  [StateModuleKind.INITIAL]: <></>,
  [StateModuleKind.GESTATIONAL]: <StyledGestational />,
  [StateModuleKind.HERNIA]: <StyledHernia />,
  [StateModuleKind.DOPPLER]: <StyledDoppler />,
  [StateModuleKind.MONOCHRONICTWINS]: <StyledMT />,
  [StateModuleKind.PREECLAMPSIA1T]: <StyledPreeclampsia />,
  [StateModuleKind.ANEMIA]: <StyledAnemia />,
  [StateModuleKind.FETALGROWTH]: <StyledFG />,
  [StateModuleKind.REFERENCES]: <></>,
}

type buttonSvgProps = {
  button: string
}

const ButtonSvg = ({button}: buttonSvgProps): JSX.Element => {
  return buttonMapper[button]
}

export default ButtonSvg
