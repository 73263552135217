import * as React from "react"
import {StyledForm} from "./styles"

interface IFormProps {
  children: React.ReactNode
  handler: React.FormEventHandler<HTMLFormElement>
}

const Form = ({children, handler}: IFormProps): JSX.Element => {
  // const methods = useFormWithSchema(schema)
  return <StyledForm onSubmit={handler}>{children}</StyledForm>
}

export default Form
