export type preeclampsiaValuesType = {
  ethnicGroup: number
  previousPE: number
  hypertension: number
  renalDisease: number
  parity: number
  weight: string
  height: string
  age: string
  diastolicBP: string
  systolicBP: string
  crl: string
  ipmaut: string
}

type preeclampsiaProps = {
  preeclampsiaValues: preeclampsiaValuesType
  dispatch: (riskEarlyPE: string) => void
}

export const preeclampsiaCalc = ({
  preeclampsiaValues,
  dispatch,
}: preeclampsiaProps): void => {
  // BEGINNING VARIABLE DEFINITION
  const {
    ethnicGroup,
    previousPE,
    hypertension,
    renalDisease,
    // parity,
    weight,
    height,
    age,
    diastolicBP,
    systolicBP,
    crl,
    ipmaut,
  } = preeclampsiaValues
  const mathLN10 = Math.LN10
  // END VARIABLE DEFINITION

  // BEGINNING Of preeclampsia calculations
  const bmi = parseFloat(weight) / (parseFloat(height) / 100) ** 2
  const map = (2 * parseFloat(diastolicBP)) / 3 + parseFloat(systolicBP) / 3
  const mapLog = Math.log(map)
  const map2 = mapLog / mathLN10
  const exput =
    0.668018 -
    0.002772 * parseFloat(crl) -
    0.001536 * parseFloat(height) -
    0.001151 * parseFloat(age)

  const momexput = Math.log(parseFloat(ipmaut)) / mathLN10 - exput
  const exmap =
    1.803485 + 0.00299 * bmi + 645e-6 * parseFloat(age) - 0.00421 * ethnicGroup

  const momexmap = map2 - exmap
  const earlyaprioriy =
    -5.617 + 2.659 * previousPE + 1.729 * hypertension + 3.765 * renalDisease

  const mathPowEarlyPrioriy = Math.pow(2.71828182845904, earlyaprioriy)
  const earlyapriorir = mathPowEarlyPrioriy / (1 + mathPowEarlyPrioriy)
  const earlyaposterioriy =
    -0.363 +
    2.653 * (Math.log(earlyapriorir) / mathLN10) +
    12.88 * momexput +
    25.915 * momexmap

  const mathPowEarlyAposterioriy = Math.pow(2.71828182845904, earlyaposterioriy)
  const earlyaposteriorir = mathPowEarlyAposterioriy / (1 + mathPowEarlyAposterioriy)

  const early = Math.round(3 / earlyaposteriorir)

  dispatch(`1/${early}`)
}
