import React from "react"
import {useTranslation} from "react-i18next"
import {
  StyledOrderedList,
  StyledReferences,
  StyledText,
  StyledTitle,
  StyledRefTitle,
} from "./styles"

const References = () => {
  const {t} = useTranslation("translation")
  return (
    <StyledReferences>
      <StyledRefTitle>{t("references.title")}</StyledRefTitle>
      <StyledTitle>{t("references.titleDating")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.datingFirst")}</StyledText>
        <StyledText>{t("references.datingSecond")}</StyledText>
        <StyledText>{t("references.datingThird")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleFG")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.fgFirst")}</StyledText>
        <StyledText>{t("references.fgSecond")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleGrowth")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.growthFirst")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleUnique")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.uniqueFirst")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleMultiple")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.multipleFirst")}</StyledText>
        <StyledText>{t("references.multipleSecond")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleHernia")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.herniaFirst")}</StyledText>
        <StyledText>{t("references.herniaSecond")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleDoppler")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.dopplerFirst")}</StyledText>
        <StyledText>{t("references.dopplerSecond")}</StyledText>
        <StyledText>{t("references.dopplerThird")}</StyledText>
        <StyledText>{t("references.dopplerFourth")}</StyledText>
        <StyledText>{t("references.dopplerFifth")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleBiometries")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.biometriesFirst")}</StyledText>
        <StyledText>{t("references.biometriesSecond")}</StyledText>
      </StyledOrderedList>

      <StyledTitle>{t("references.titleMonochronicTwins")}</StyledTitle>
      <StyledOrderedList>
        <StyledText>{t("references.monochronicTwinsFirst")}</StyledText>
      </StyledOrderedList>
    </StyledReferences>
  )
}

export default References
