import React from "react"
import LeftRightTab from "../../components/LeftRightTab"
import {StyledHernia, StyledHeader, StyledTab} from "./styles"
import {useTranslation} from "react-i18next"
import LHR from "./LHR"
import POS from "./POS"
import {useStore} from "../../store"
import {HerniaActionKind} from "../../store/Reducers/Modules/hernia"
import ImageHeader from "../../components/ImageHeader"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"

export type side = "left" | "right"

const Hernia = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  return (
    <StyledHernia>
      <ImageHeader module={StateModuleKind.HERNIA} />
      <StyledHeader>{t("hernia.header")}</StyledHeader>
      <StyledTab>
        <LeftRightTab
          name={t("hernia.leftTab")}
          active={state.herniaValues.side == "left"}
          toggle={() =>
            dispatch({action: HerniaActionKind.UPDATE_SIDE, payload: "left"})
          }
        />
        <LeftRightTab
          name={t("hernia.rightTab")}
          active={state.herniaValues.side == "right"}
          toggle={() =>
            dispatch({action: HerniaActionKind.UPDATE_SIDE, payload: "right"})
          }
        />
      </StyledTab>
      <LHR />
      <POS />
    </StyledHernia>
  )
}

export default Hernia
