import styled, {css} from "styled-components"

export const StyledBasicOutput = styled.span<OutputProps>(
  ({theme: {colors, weights, typography}, length, border}) => css`
    background: ${colors.outputBackground};
    ${border && "border: 1px solid" + colors.outputBorder + ";"}
    height: 50px;
    // Longitudes provisorias hasta que pueda probar mas componentes juntos
    width: ${length}%;
    margin: 2%;
    align-self: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;

    * {
      font-weight: ${weights.regular};
      font-size: ${typography.medium};
    }
  `
)

interface OutputProps {
  readonly length?: number
  readonly border?: boolean
}

export const StyledOutputContainer = styled.div(
  () => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  `
)
