import React from "react"
import {StyledHamburguer} from "./styles"

export type HamburguerProps = {
  toggle: () => void
}

const Hamburguer = ({toggle}: HamburguerProps): JSX.Element => {
  return <StyledHamburguer onClick={toggle} />
}

export default Hamburguer
