import React, {useState} from "react"
import {StyledThird} from "./styles"
import DropDownHeader from "../../../../components/DropDown/Header"
import DropDownBody from "../../../../components/DropDown/Body"
import Form from "../../../../components/Form"
import {InColumnLabel} from "../../../../components/LabelContainer"
import BasicLabel from "../../../../components/BasicLabel"
import BasicInput from "../../../../components/BasicInput"
import CalculateButton from "../../../../components/CalculateButton"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledBlackText, StyledGreyText} from "../../../../components/Texts/styles"
import {useTranslation} from "react-i18next"
import ThirdTM from "../../../../components/Form/Validations/ThirdTM"
import {useStore} from "../../../../store"
import {GestationalActionKind} from "../../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const Third = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(ThirdTM),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (ga: string, lmp: string, fur: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_THIRD,
      payload: {
        ...state.gestationalValues.third,
        ga: ga,
        lmp: lmp,
        fur: fur,
      },
    })
  }

  return (
    <StyledThird>
      <DropDownHeader
        name={t("gestational.dating.third.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody white nested>
          <Form handler={handleSubmit(submitHandler)}>
            <InColumnLabel>
              <BasicLabel name="headDiameter">
                {t("gestational.dating.third.headDiameter")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                length={96}
                name="headDiameter"
                type="tel"
                value={state.gestationalValues.third.headDiameter}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_THIRD,
                    payload: {...state.gestationalValues.third, headDiameter: value},
                  })
                }
              />
            </InColumnLabel>
            <InColumnLabel>
              <BasicLabel name="dateUltrasound">
                {t("gestational.dating.third.dateInput")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                length={96}
                name="dateUltrasound"
                type="date"
                value={state.gestationalValues.third.dateUltrasound}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_THIRD,
                    payload: {
                      ...state.gestationalValues.third,
                      dateUltrasound: value,
                    },
                  })
                }
              />
            </InColumnLabel>

            <CalculateButton />

            <BasicOutput value={state.gestationalValues.third.ga} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.third.gaOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.third.egOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.third.lmp} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.third.lpmOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.third.furOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.third.fur} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.third.furByOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.third.fppOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>
          </Form>
        </DropDownBody>
      )}
    </StyledThird>
  )
}

export default Third
