import styled, {css} from "styled-components"

export const StyledDrawerContainer = styled.div<StyledDrawerProps>(
  ({isVisible}) => css`
    width: ${/Android|iPhone|iPad/i.test(navigator.userAgent) ? "100%" : "40%"};
    background: transparent;
    z-index: ${isVisible ? "3" : "0"};
    left: ${/Android|iPhone|iPad/i.test(navigator.userAgent) ? "0" : "30%"};
    top: 0;
    height: 100%;
    position: ${isVisible ? "fixed" : "absolute"};
  `
)

export const StyledDrawer = styled.div<StyledDrawerProps>(
  ({theme: {colors}, isVisible}) => css`
    height: 100%;
    position: ${isVisible ? "fixed" : "absolute"};
    background-color: ${isVisible ? colors.drawerBackground : "transparent"};
    transition: 1s;
    padding-top: 60px;
    ${/Android|iPhone|iPad/i.test(navigator.userAgent)
      ? `width: ${isVisible ? "60%" : "40px"};`
      : `width: ${isVisible ? "30%" : "8%"};`}
  `
)

interface StyledDrawerProps {
  readonly isVisible: boolean
}

export const HamburguerContainer = styled.div<StyledDrawerProps>(
  ({theme: {colors}, isVisible}) => css`
    margin-top: -20px;
    ${isVisible ? "margin-right: 20px;" : "margin-left: 2%;"}
    float: right;
    transition: 1s;
    z-index: 3;

    & path {
      ${isVisible ? `fill: ${colors.drawerItemColor};` : ""}
    }
  `
)

export const StyledItem = styled.p<StyledDrawerProps>(
  ({theme: {colors, weights, typography}, isVisible}) => css`
    margin-top: 30px;
    margin-left: 5vw;
    font-style: normal;
    font-weight: ${weights.weak};
    font-size: ${typography.medium};
    line-height: 19px;
    visibility: ${isVisible ? "visible" : "hidden"};
    display: "flex";
    align-items: flex-end;
    color: ${colors.drawerItemColor};
  `
)
