import React, {useState} from "react"
import {StyledDating} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import Last from "./Last"
import First from "./First"
import Second from "./Second"
import Third from "./Third"
import {useTranslation} from "react-i18next"

const Dating = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  return (
    <StyledDating>
      <DropDownHeader
        name={t("gestational.dating.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody>
          <Last />
          <First />
          <Second />
          <Third />
        </DropDownBody>
      )}
    </StyledDating>
  )
}

export default Dating
