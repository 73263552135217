import React from "react"
import {StyledBasicOutput, StyledOutputContainer} from "./styles"

type OutputProps = {
  length: number
  children: React.ReactNode
  value: string
  border?: boolean
}

const BasicOutput = ({
  length = 30,
  children,
  value,
  border = false,
}: Partial<OutputProps>): JSX.Element => {
  return (
    <StyledOutputContainer>
      {children}
      <StyledBasicOutput length={length} border={border}>
        {value}
      </StyledBasicOutput>
    </StyledOutputContainer>
  )
}

export default BasicOutput
