import React from "react"
import {StateModuleKind} from "../../store/Reducers/Modules/menu"
import {
  StyledSvgContainer,
  StyledTextHeader,
  StyledGestationalImage,
  StyledHerniaImage,
  StyledDopplerImage,
  StyledMTImage,
  StyledPreeclampsiaImage,
  StyledAnemiaImage,
  StyledFGImage,
} from "./styles"

type ImageMapperType = {[key: string]: JSX.Element}

const ImageMapper: ImageMapperType = {
  [StateModuleKind.GESTATIONAL]: <StyledGestationalImage />,
  [StateModuleKind.HERNIA]: <StyledHerniaImage />,
  [StateModuleKind.DOPPLER]: <StyledDopplerImage />,
  [StateModuleKind.MONOCHRONICTWINS]: <StyledMTImage />,
  [StateModuleKind.PREECLAMPSIA1T]: <StyledPreeclampsiaImage />,
  [StateModuleKind.ANEMIA]: <StyledAnemiaImage />,
  [StateModuleKind.FETALGROWTH]: <StyledFGImage />,
}

type ImageHeaderProps = {
  module: string
}

const ImageHeader = ({module}: ImageHeaderProps): JSX.Element => {
  return (
    <StyledSvgContainer>
      <StyledTextHeader>{module}</StyledTextHeader>
      {ImageMapper[module]}
    </StyledSvgContainer>
  )
}

export default ImageHeader
