import React from "react"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"
import {useTranslation} from "react-i18next"
import BasicInput from "../../../../../components/BasicInput"
import Select from "../../../../../components/Select"
import {BladderSelect} from "../../../../../utils/SelectArrays"
import {StyledLabel} from "../styles"
import {StyledColumn, StyledHeader, StyledTextColumn} from "./styles"

type ColumnProps = {
  left: boolean
  errors?: FieldErrorsImpl<FieldValues>
  register: UseFormRegister<FieldValues>
  setRefValue: (
    name: string,
    value: number,
    config?: Record<string, unknown>
  ) => void
  mvpValue: string
  mvpSetValue: (value: string) => void
  bladderValue: {[key: string]: number}
  bladderSetValue: (value: {[key: string]: number}) => void
  efwValue: string
  efwSetValue: (value: string) => void
  abdominalCValue: string
  abdominalCSetValue: (value: string) => void
  psvValue: string
  psvSetValue: (value: string) => void
}

export const Column = ({
  left,
  errors,
  register,
  setRefValue,
  mvpValue,
  mvpSetValue,
  bladderValue,
  bladderSetValue,
  efwValue,
  efwSetValue,
  abdominalCValue,
  abdominalCSetValue,
  psvValue,
  psvSetValue,
}: ColumnProps): JSX.Element => {
  const {t} = useTranslation("translation")
  const inputsNumber = left ? "One" : "Two"
  return (
    <StyledColumn left={left}>
      <StyledHeader>
        {t("monochronicTwins.TWIN")}
        {left ? " 1" : " 2"}
      </StyledHeader>
      <BasicInput
        errors={errors}
        register={register}
        name={`maximumVerticalPocket${inputsNumber}`}
        type="tel"
        length={100}
        value={mvpValue}
        setValue={(v) => mvpSetValue(v)}
      />
      <Select
        errors={errors}
        register={register}
        setRefValue={setRefValue}
        name={`bladder${inputsNumber}`}
        data={BladderSelect()}
        value={bladderValue}
        setValue={(v) => bladderSetValue(v)}
      />
      <BasicInput
        errors={errors}
        register={register}
        name={`estimatedFetalWeight${inputsNumber}`}
        type="tel"
        length={100}
        value={efwValue}
        setValue={(v) => efwSetValue(v)}
      />
      <BasicInput
        errors={errors}
        register={register}
        name={`abdominalCircumference${inputsNumber}`}
        type="tel"
        length={100}
        value={abdominalCValue}
        setValue={(v) => abdominalCSetValue(v)}
      />
      <BasicInput
        errors={errors}
        register={register}
        name={`peakSystolicVelocity${inputsNumber}`}
        type="tel"
        length={100}
        value={psvValue}
        setValue={(v) => psvSetValue(v)}
      />
    </StyledColumn>
  )
}

export const TextColumn = (): JSX.Element => {
  const {t} = useTranslation("translation")
  return (
    <StyledTextColumn>
      <StyledLabel>
        {t(
          `monochronicTwins.diagnosticAlgorithm.twinsAlgorithm.maximumVerticalPocket`
        )}
      </StyledLabel>
      <StyledLabel>
        {t(`monochronicTwins.diagnosticAlgorithm.twinsAlgorithm.bladder`)}
      </StyledLabel>
      <StyledLabel>
        {t(
          `monochronicTwins.diagnosticAlgorithm.twinsAlgorithm.estimatedFetalWeight`
        )}
      </StyledLabel>
      <StyledLabel>
        {t(
          `monochronicTwins.diagnosticAlgorithm.twinsAlgorithm.abdominalCircumference`
        )}
      </StyledLabel>
      <StyledLabel>
        {t(
          `monochronicTwins.diagnosticAlgorithm.twinsAlgorithm.peakSystolicVelocity`
        )}
      </StyledLabel>
    </StyledTextColumn>
  )
}
