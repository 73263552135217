import {stateType} from "../.."

export enum DopplerActionKind {
  UPDATE_WEEKS = "UPDATE_WEEKS",
  UPDATE_DAYS = "UPDATE_DAYS",
  UPDATE_UMBILICAL = "UPDATE_UMBILICAL",
  UPDATE_MIDDLE_CEREBRAL = "UPDATE_MIDDLE_CEREBRAL",
  UPDATE_DUCTUS = "UPDATE_DUCTUS",
  UPDATE_AORTIC = "UPDATE_AORTIC",
  UPDATE_CEREBRO = "UPDATE_CEREBRO",
  UPDATE_UTERINE = "UPDATE_UTERINE",
  UPDATE_TEI = "UPDATE_TEI",
}

interface DopplerModule {
  action: DopplerActionKind
  payload: string | {[key: string]: string}
}

export const dopplerReducer = (
  state: stateType,
  {action, payload}: DopplerModule
) => {
  switch (action) {
    case DopplerActionKind.UPDATE_WEEKS:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, weeks: payload},
      }
    case DopplerActionKind.UPDATE_DAYS:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, days: payload},
      }
    case DopplerActionKind.UPDATE_UMBILICAL:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, umbilical: payload},
      }
    case DopplerActionKind.UPDATE_MIDDLE_CEREBRAL:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, middleCerebral: payload},
      }
    case DopplerActionKind.UPDATE_DUCTUS:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, ductus: payload},
      }
    case DopplerActionKind.UPDATE_AORTIC:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, aortic: payload},
      }
    case DopplerActionKind.UPDATE_CEREBRO:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, cerebroPlacental: payload},
      }
    case DopplerActionKind.UPDATE_UTERINE:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, uterine: payload},
      }
    case DopplerActionKind.UPDATE_TEI:
      return {
        ...state,
        dopplerValues: {...state.dopplerValues, tei: payload},
      }
    default:
      return state
  }
}
