import {yupResolver} from "@hookform/resolvers/yup"
import React, {useEffect, useState} from "react"
import {FieldErrorsImpl, FieldValues, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import BasicInput from "../../../components/BasicInput"
import BasicLabel from "../../../components/BasicLabel"
import BasicOutput from "../../../components/BasicOutput"
import CalculateButton from "../../../components/CalculateButton"
import DropDownBody from "../../../components/DropDown/Body"
import DropDownHeader from "../../../components/DropDown/Header"
import Form from "../../../components/Form"
import CerebroPlacentalSchema from "../../../components/Form/Validations/CerebroPlacental"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import {useStore} from "../../../store"
import {CerebroPlacentalInitStateType} from "../../../store/Initials/doppler"
import {DopplerActionKind} from "../../../store/Reducers/Modules/doppler"
import {calcCerebro} from "../../../utils/algorithms/doppler"
import {StyledConfinedSpace} from "../styles"

type CerebroProps = {
  ManageGAErrors: (errors: FieldErrorsImpl<FieldValues> | undefined) => void
}

const Cerebro = ({ManageGAErrors}: CerebroProps) => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(CerebroPlacentalSchema),
  })

  const submitHandler = (values: FieldValues) => {
    // Se llama ManageGAErrors con undefined para que se limpien los errores
    ManageGAErrors(undefined)
    calcCerebro({
      cerebroValues: values as CerebroPlacentalInitStateType,
      dispatch: updateOutputs,
    })
  }

  const errorHandler = (errors: FieldErrorsImpl<FieldValues>) => {
    ManageGAErrors(errors)
  }

  const updateOutputs = (
    cerebroRatio: string,
    percentil: string,
    results: string
  ) => {
    dispatch({
      action: DopplerActionKind.UPDATE_CEREBRO,
      payload: {
        ...state.dopplerValues.cerebroPlacental,
        cerebroRatio: cerebroRatio,
        percentil: percentil,
        results: results,
      },
    })
  }

  useEffect(() => {
    setValue("weeks", state.dopplerValues.weeks)
  }, [state.dopplerValues.weeks])

  useEffect(() => {
    setValue("days", state.dopplerValues.days)
  }, [state.dopplerValues.days])

  return (
    <>
      <DropDownHeader
        name={t("doppler.cerebro.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}
      />

      {active && (
        <StyledConfinedSpace>
          <DropDownBody nested white>
            <Form handler={handleSubmit(submitHandler, errorHandler)}>
              <InColumnLabel>
                <BasicLabel name="picma">{t("doppler.cerebro.picma")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="picma"
                  type="tel"
                  value={state.dopplerValues.cerebroPlacental.picma}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_CEREBRO,
                      payload: {...state.dopplerValues.cerebroPlacental, picma: v},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="piua">{t("doppler.cerebro.piua")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  name="piua"
                  type="tel"
                  value={state.dopplerValues.cerebroPlacental.piua}
                  length={96}
                  setValue={(v) =>
                    dispatch({
                      action: DopplerActionKind.UPDATE_CEREBRO,
                      payload: {...state.dopplerValues.cerebroPlacental, piua: v},
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <BasicLabel name="cerebroRatio">
                  {t("doppler.cerebro.cerebroRatio")}
                </BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.cerebroPlacental.cerebroRatio}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="percentil">
                  {t("doppler.cerebro.percentil")}
                </BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.cerebroPlacental.percentil}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="results">
                  {t("doppler.cerebro.results")}
                </BasicLabel>
                <BasicOutput
                  value={state.dopplerValues.cerebroPlacental.results}
                  length={100}
                />
              </InLineLabel>
            </Form>
          </DropDownBody>
        </StyledConfinedSpace>
      )}
    </>
  )
}

export default Cerebro
