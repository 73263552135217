import dopplerInitState from "./doppler"
import herniaInintState from "./hernia"
import calculatorState from "./menu"
import monochronicTwinsInitState from "./monochronicTwins"
import preeclampsiaInitState from "./preeclampsia"
import gestationalInitState from "./gestational"
import anemiaInitState from "./anemia"

export const storeInitials = {
  ...calculatorState,
  ...dopplerInitState,
  ...preeclampsiaInitState,
  ...monochronicTwinsInitState,
  ...herniaInintState,
  ...gestationalInitState,
  ...anemiaInitState,
}
