import styled, {css} from "styled-components"

export const StyledButton = styled.button<ButtonProps>(
  ({theme: {colors, weights, typography}, active}) => css`
    background: ${active
      ? colors.buttonActiveBackground
      : colors.buttonPassiveBackground};
    width: 44%;
    margin: 3%;
    aspect-ratio: 1/1;
    border-radius: 8px;
    border: none;
    color: ${colors.activeText};
    font-weight: ${weights.regular};
    font-size: ${typography.medium};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  `
)

interface ButtonProps {
  readonly active?: boolean
}
