import React from "react"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"

import {StyledWeeksDaysContainer, StyledWeeksDays} from "./styles"
import {useTranslation} from "react-i18next"
import BasicLabel from "../BasicLabel"
import {InLineLabel} from "../LabelContainer"
import BasicInput from "../BasicInput"

type WeeksDaysProps = {
  nameWeek?: string
  nameDay?: string
  disabled?: boolean
  weeks: string
  setWeeks: (value: string) => void
  days: string
  setDays: (value: string) => void
  register: UseFormRegister<FieldValues>
  errors?: FieldErrorsImpl<FieldValues>
}

const WeeksDaysInputs = ({
  nameWeek = "weeks",
  nameDay = "days",
  errors,
  weeks,
  setWeeks,
  days,
  setDays,
  disabled = false,
  register,
}: WeeksDaysProps) => {
  const {t} = useTranslation("translation")
  return (
    <StyledWeeksDaysContainer>
      <InLineLabel>
        <StyledWeeksDays>
          <BasicLabel name="weeks" disabled={disabled}>
            {t("gestational.growthCentile.weeks")}
          </BasicLabel>
          <BasicInput
            register={register}
            disabled={disabled}
            setValue={(value) => setWeeks(value)}
            value={weeks}
            name={nameWeek}
            type="tel"
            errors={errors}
          />
        </StyledWeeksDays>
        <StyledWeeksDays>
          <BasicLabel name="days" disabled={disabled}>
            {t("gestational.growthCentile.days")}
          </BasicLabel>
          <BasicInput
            register={register}
            disabled={disabled}
            setValue={(value) => setDays(value)}
            value={days}
            name={nameDay}
            type="tel"
            errors={errors}
          />
        </StyledWeeksDays>
      </InLineLabel>
    </StyledWeeksDaysContainer>
  )
}

export default WeeksDaysInputs
