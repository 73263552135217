import React, {useState} from "react"
import {StyledDBP} from "./styles"
import DropDownHeader from "../../../../components/DropDown/Header"
import DropDownBody from "../../../../components/DropDown/Body"
import {InColumnLabel, InLineLabel} from "../../../../components/LabelContainer"
import BasicInput from "../../../../components/BasicInput"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledGreyText} from "../../../../components/Texts/styles"
import BasicLabel from "../../../../components/BasicLabel"
import Form from "../../../../components/Form"
import DBPSchema from "../../../../components/Form/Validations/DBP"
import {useTranslation} from "react-i18next"
import CalculateButton from "../../../../components/CalculateButton"
import {useStore} from "../../../../store"
import {GestationalActionKind} from "../../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const DBP = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(DBPSchema),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (weight: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_DBP,
      payload: {
        ...state.gestationalValues.dbp,
        weight: weight,
      },
    })
  }

  // Deberia tener un useEffect para que cada vez que confirmedLPM cambie se realize un submit del formulario
  return (
    <StyledDBP>
      <DropDownHeader
        name={t("gestational.estimateFetalWeight.dbp.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}></DropDownHeader>

      {active && (
        <DropDownBody white nested>
          <Form handler={handleSubmit(submitHandler)}>
            <InLineLabel>
              <InColumnLabel>
                <BasicLabel name="dbp">
                  {t("gestational.estimateFetalWeight.dbp.dbp")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={90}
                  name="dbp"
                  type="tel"
                  value={state.gestationalValues.dbp.dbp}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_DBP,
                      payload: {...state.gestationalValues.dbp, dbp: value},
                    })
                  }
                />
              </InColumnLabel>
              <InColumnLabel>
                <BasicLabel name="hc">
                  {t("gestational.estimateFetalWeight.dbp.hc")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={90}
                  name="hc"
                  type="tel"
                  value={state.gestationalValues.dbp.hc}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_DBP,
                      payload: {...state.gestationalValues.dbp, hc: value},
                    })
                  }
                />
              </InColumnLabel>
            </InLineLabel>
            <InLineLabel>
              <InColumnLabel>
                <BasicLabel name="ac">
                  {t("gestational.estimateFetalWeight.dbp.ac")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={90}
                  name="ac"
                  type="tel"
                  value={state.gestationalValues.dbp.ac}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_DBP,
                      payload: {...state.gestationalValues.dbp, ac: value},
                    })
                  }
                />
              </InColumnLabel>
              <InColumnLabel>
                <BasicLabel name="fl">
                  {t("gestational.estimateFetalWeight.dbp.fl")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={90}
                  name="fl"
                  type="tel"
                  value={state.gestationalValues.dbp.fl}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_DBP,
                      payload: {...state.gestationalValues.dbp, fl: value},
                    })
                  }
                />
              </InColumnLabel>
            </InLineLabel>

            <CalculateButton />

            {/* Uso el InlineLabel en lugar de mandarle el styled grey text como children al output por motivos de estilos*/}
            <InLineLabel>
              <StyledGreyText>
                {t("gestational.estimateFetalWeight.dbp.weightsOutput")}
              </StyledGreyText>
              <BasicOutput value={state.gestationalValues.dbp.weight} length={100} />
            </InLineLabel>
          </Form>
        </DropDownBody>
      )}
    </StyledDBP>
  )
}

export default DBP
