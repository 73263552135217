import styled, {css} from "styled-components"

export const StyledWeeksDaysContainer = styled.div(() => css``)

export const StyledWeeksDays = styled.div(
  () => css`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: stetch;
    flex-direction: row;
  `
)
