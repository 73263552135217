import buttons from "./Buttons/index.json"
import monochronicTwins from "./MonochronicTwins/index.json"
import select from "./Select/index.json"
import hernia from "./Hernia/index.json"
import gestational from "./Gestational/index.json"
import preeclampsia from "./Preeclampsia/index.json"
import anemia from "./Anemia/index.json"
import fetalGrowth from "./FetalGrowth/index.json"
import doppler from "./Doppler/index.json"
import references from "./References/index.json"

type translation = {
  [key: string]: string | translation | Array<string> | {[key: string]: number}
}

type Translations = Record<string, translation>

const translation: Translations = {
  buttons,
  monochronicTwins,
  select,
  hernia,
  gestational,
  preeclampsia,
  anemia,
  fetalGrowth,
  references,
  doppler,
}

export default translation
