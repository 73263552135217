import React, {useState} from "react"
import {StyledSecond} from "./styles"
import DropDownHeader from "../../../../components/DropDown/Header"
import DropDownBody from "../../../../components/DropDown/Body"
import Form from "../../../../components/Form"
import {InColumnLabel} from "../../../../components/LabelContainer"
import BasicLabel from "../../../../components/BasicLabel"
import BasicInput from "../../../../components/BasicInput"
import CalculateButton from "../../../../components/CalculateButton"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledBlackText, StyledGreyText} from "../../../../components/Texts/styles"
import {useTranslation} from "react-i18next"
import SecondTM from "../../../../components/Form/Validations/SecondTM"
import {useStore} from "../../../../store"
import {GestationalActionKind} from "../../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const Second = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(SecondTM),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (ga: string, lmp: string, fur: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_SECOND,
      payload: {
        ...state.gestationalValues.second,
        ga: ga,
        lmp: lmp,
        fur: fur,
      },
    })
  }

  return (
    <StyledSecond>
      <DropDownHeader
        name={t("gestational.dating.second.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody white nested>
          <Form handler={handleSubmit(submitHandler)}>
            <InColumnLabel>
              <BasicLabel name="biparietalDiameter">
                {t("gestational.dating.second.biparietalDiameter")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                length={96}
                name="biparietalDiameter"
                type="tel"
                value={state.gestationalValues.second.biparietalDiameter}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_SECOND,
                    payload: {
                      ...state.gestationalValues.second,
                      biparietalDiameter: value,
                    },
                  })
                }
              />
            </InColumnLabel>
            <InColumnLabel>
              <BasicLabel name="dateUltrasound">
                {t("gestational.dating.second.dateInput")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                length={96}
                name="dateUltrasound"
                type="date"
                value={state.gestationalValues.second.dateUltrasound}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_SECOND,
                    payload: {
                      ...state.gestationalValues.second,
                      dateUltrasound: value,
                    },
                  })
                }
              />
            </InColumnLabel>

            <CalculateButton />

            <BasicOutput value={state.gestationalValues.second.ga} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.second.gaOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.second.egOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.second.lmp} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.second.lpmOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.second.furOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.second.fur} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.second.furByOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.second.fppOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>
          </Form>
        </DropDownBody>
      )}
    </StyledSecond>
  )
}

export default Second
