import {menuReducer} from "./Modules/menu"
import {dopplerReducer} from "./Modules/doppler"
import {preeclampsiaReducer} from "./Modules/preeclampsia"
import {monochronicTwinsReducer} from "./Modules/monochronicTwins"
import {herniaReducer} from "./Modules/hernia"
import {gestationalReducer} from "./Modules/gestational"
import {anemiaReducer} from "./Modules/anemia"

const reducers = [
  menuReducer,
  dopplerReducer,
  preeclampsiaReducer,
  monochronicTwinsReducer,
  herniaReducer,
  gestationalReducer,
  anemiaReducer,
]

// Configurar bien los tipos

/* eslint-disable @typescript-eslint/no-explicit-any */
const combineReducers = (reducers: any) => (state: any, action: any) =>
  reducers.reduce((newState: any, reducer: any) => reducer(newState, action), state)

/* eslint-enable @typescript-eslint/no-explicit-any */
export default combineReducers(reducers)
