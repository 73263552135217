import React from "react"
import {
  StyledInlineContainer,
  StyledLabelContainer,
  StyledRestContainer,
  StyledInColumnContainer,
} from "./styles"

export type ContainerProps = {
  children: Array<React.ReactNode>
}

const StylessContainer = ({children}: ContainerProps): JSX.Element => {
  return (
    <>
      <StyledLabelContainer>{children[0]}</StyledLabelContainer>
      <StyledRestContainer>{children[1]}</StyledRestContainer>
    </>
  )
}

export const InLineLabel = ({children}: ContainerProps): JSX.Element => {
  return (
    <StyledInlineContainer>
      <StylessContainer>{children}</StylessContainer>
    </StyledInlineContainer>
  )
}

export const InColumnLabel = ({children}: ContainerProps): JSX.Element => {
  return (
    <StyledInColumnContainer>
      <StylessContainer>{children}</StylessContainer>
    </StyledInColumnContainer>
  )
}
