import styled, {css} from "styled-components"

export const StyledLabelContainer = styled.div(
  () =>
    css`
      align-self: center;
    `
)

export const StyledBasicLabel = styled.label<LabelProps>(
  ({theme: {weights, typography, colors}, heavy, disabled}) => css`
    font-weight: ${heavy ? weights.regular : weights.weak};
    font-size: ${typography.medium};
    padding-right: 20px;
    ${disabled ? "color:" + colors.disabled + ";" : ""}
  `
)

interface LabelProps {
  readonly for?: string
  readonly heavy?: boolean
  readonly disabled?: boolean
}
