import {
  CerebralDopplerInitStateType,
  CVTInitStateType,
} from "../../store/Initials/anemia"
import {roundToTwo} from "./round"

type cerebralDopplerProps = {
  cerebralDopplerValues: CerebralDopplerInitStateType
  dispatch: (expectedPSV: string, mom: string, anemiaLevel: string) => void
}

export const calcCerebralDoppler = ({
  cerebralDopplerValues,
  dispatch,
}: cerebralDopplerProps) => {
  const totalWeeks =
    Number(cerebralDopplerValues.weeks) + Number(cerebralDopplerValues.days) / 7
  const expectedPSV = roundToTwo(Math.exp(2.31 + 0.046 * totalWeeks))
  cerebralDopplerValues.expectedPSV = `${expectedPSV}`

  const mom = roundToTwo(Number(cerebralDopplerValues.mca) / expectedPSV)
  cerebralDopplerValues.mom = `${mom}`
  let res = "Anemia Severa"
  if (mom <= 1.3) {
    res = "NORMAL (NO Anemia)"
  } else if (mom <= 1.5) {
    res = "Anemia Leve"
  } else if (mom <= 1.6) {
    res = "Anemia Moderada"
  }
  dispatch(cerebralDopplerValues.expectedPSV, cerebralDopplerValues.mom, res)
}

type volumeProps = {
  volumeValues: CVTInitStateType
  dispatch: (volume: string) => void
}

export const calcVolume = ({volumeValues, dispatch}: volumeProps) => {
  const totalWeeks = Number(volumeValues.weeks) + Number(volumeValues.days) / 7
  const volumeES = 102 - 12.17 * totalWeeks + 0.444 * totalWeeks * totalWeeks

  const observedHema = Number(volumeValues.observedHema)
  const desiredHema = Number(volumeValues.desiredHema)
  const donoHema = Number(volumeValues.donoHema)
  const volume = (volumeES * (observedHema - desiredHema)) / (desiredHema - donoHema)
  volumeValues.volume = `${Math.round(volume)}`
  dispatch(volumeValues.volume)
}
