import React from "react"
import BasicLabel from "../../../../../components/BasicLabel"
import GenderRadio, {genders} from "../../../../../components/GenderRadio"
import {InColumnLabel} from "../../../../../components/LabelContainer"
import {useTranslation} from "react-i18next"
import {useStore} from "../../../../../store"
import {MonochronicTwinsActionKind} from "../../../../../store/Reducers/Modules/monochronicTwins"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"

type GenderRadioProps = {
  register: UseFormRegister<FieldValues>
  errors?: FieldErrorsImpl<FieldValues>
}

const GenderRadioMT = ({register, errors}: GenderRadioProps): JSX.Element => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  return (
    <InColumnLabel>
      <BasicLabel name="twinsGender">
        {t("monochronicTwins.diagnosticAlgorithm.twinsAlgorithm.twinsGenderLabel")}
      </BasicLabel>
      <GenderRadio
        errors={errors}
        register={register}
        value={state.monochronicTwinsValues.twinsGender}
        setValue={(v) =>
          dispatch({
            action: MonochronicTwinsActionKind.UPDATE_TWINS_GENDER,
            payload: v as genders,
          })
        }
        label="twinsGender"
      />
    </InColumnLabel>
  )
}

export default GenderRadioMT
