import * as yup from "yup"

const UniquePercentileSchema = yup.object({
  genderUnique: yup.string().nullable().required("Required"),
  efw: yup.string().required("Required"),
  weeks: yup.string().required("Required"),
  days: yup.string().required("Required"),
})

export default UniquePercentileSchema
