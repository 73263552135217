import * as yup from "yup"

const CerebralDopplerSchema = yup.object({
  weeks: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 14", (val) => Number(val) >= 14)
    .test("range", "Cant be higher than 44", (val) => Number(val) <= 44),
  days: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Can only be 6 or lower", (val) => Number(val) <= 6),
  mca: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be equal or lower than 10", (val) => Number(val) > 10)
    .test("range", "Cant be higher than 150", (val) => Number(val) <= 150),
})

export default CerebralDopplerSchema
