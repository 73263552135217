export const colors: Colors = {
  // El background color de la app se debe cambiar desde globalStyles.ts (esta fuera del theme por lo que hay que hacerlo a manopla)
  background: "#212121",
  greyBackground: "#6C6C6C",
  whiteIshBackground: "#ededed",

  buttonActiveBackground: "#1977CC",
  buttonPassiveBackground: "#414245",
  clearValuesBackground: "#FFFFFF",
  clearValuesColor: "#1977CC",

  activeText: "#FFFFFF",
  passiveText: "#000000",

  dropdownHeaderBackground: "#6C6C6C",
  primaryDropeddownBackground: "#EDEDED",
  primaryDropeddownBorder: "#EDEDED",
  secondaryDropeddownBackground: "#EDEDED",
  secondaryDropeddownBorder: "#6C6C6C",

  inputBackground: "#FFFFFF",
  inputBorder: "#FFFFFF",

  outputBackground: "#D2D2D2",
  outputBorder: "#D2D2D2",

  radioButton: "#212121",
  headerGreyBackground: "#DADADA",
  disabled: "#9E9E9E",

  dopplerOutputsBorder: "#251E70",
  leftRightActiveBackground: "#187DC7",
  leftRightPassiveBackground: "#FFFFFF",
  leftRightActiveText: "#FFFFFF",
  leftRightPassiveText: "#888888",
  leftRightTabBackground: "#EDEDED",

  selectActiveBackground: "#EDEDED",
  selectPassiveBackground: "#FFFFFF",
  selectBorder: "#9E9E9E",

  blackText: "#000000",
  greyText: "#9E9E9E",

  twinTableBackground: "#EDEDED",
  twinTableBorder: "#9E9E9E",

  errorText: "#DC0404",
  inputErrorBorder: "#E30202",

  drawerBackground: "#D2D2D2",
  drawerItemColor: "#212121",
}

export type Colors = Record<string, string>
