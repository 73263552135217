import React, {useState} from "react"
import {StyledUniquePercentile, StyledBodyContainer} from "./styles"
import DropDownHeader from "../../../components/DropDown/Header"
import DropDownBody from "../../../components/DropDown/Body"
import {GestationalActionKind} from "../../../store/Reducers/Modules/gestational"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import GenderRadio, {genders} from "../../../components/GenderRadio"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import {StyledGreyText} from "../../../components/Texts/styles"
import Form from "../../../components/Form"
import UniquePercentileSchema from "../../../components/Form/Validations/UniquePercentile"
import WeeksDaysInputs from "../../../components/WeeksDaysInputs"
import {useStore} from "../../../store"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const UniquePercentile = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(UniquePercentileSchema),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (output: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_UNIQUE,
      payload: {
        ...state.gestationalValues.unique,
        output: output,
      },
    })
  }

  return (
    <StyledUniquePercentile>
      <DropDownHeader
        name={t("gestational.uniquePercentile.header")}
        active={active}
        toggle={() => setActive(!active)}></DropDownHeader>
      {active && (
        <DropDownBody>
          <Form handler={handleSubmit(submitHandler)}>
            <StyledBodyContainer>
              <InColumnLabel>
                <BasicLabel name="efw">
                  {t("gestational.uniquePercentile.efw")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="efw"
                  type="tel"
                  value={state.gestationalValues.unique.efw}
                  setValue={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_UNIQUE,
                      payload: {...state.gestationalValues.unique, efw: value},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="time">
                  {t("gestational.uniquePercentile.ga")}
                </BasicLabel>

                <WeeksDaysInputs
                  errors={errors}
                  register={register}
                  weeks={state.gestationalValues.unique.weeks}
                  setWeeks={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_UNIQUE,
                      payload: {...state.gestationalValues.unique, weeks: value},
                    })
                  }
                  days={state.gestationalValues.unique.days}
                  setDays={(value) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_UNIQUE,
                      payload: {...state.gestationalValues.unique, days: value},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="genderUnique">
                  {t("gestational.uniquePercentile.genderLabel")}
                </BasicLabel>
                <GenderRadio
                  errors={errors}
                  register={register}
                  value={state.gestationalValues.unique.genderUnique}
                  setValue={(v) =>
                    dispatch({
                      action: GestationalActionKind.UPDATE_UNIQUE,
                      payload: {
                        ...state.gestationalValues.unique,
                        genderUnique: v as genders,
                      },
                    })
                  }
                  label="genderUnique"
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <StyledGreyText>
                  {t("gestational.uniquePercentile.output")}
                </StyledGreyText>
                <BasicOutput
                  value={state.gestationalValues.unique.output}
                  length={100}
                  border
                />
              </InLineLabel>
            </StyledBodyContainer>
          </Form>
        </DropDownBody>
      )}
    </StyledUniquePercentile>
  )
}

export default UniquePercentile
