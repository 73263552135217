import * as yup from "yup"

const CerebroPlacentalSchema = yup.object({
  weeks: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 20", (val) => Number(val) >= 20)
    .test("range", "Cant be higher than 44", (val) => Number(val) <= 44),
  days: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Can only be 6 or lower", (val) => Number(val) <= 6),
  picma: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+(\.[0-9]+)?$/, "Must be only digits")
    .test("range", "Cant be lower than 0.1", (val) => Number(val) >= 0.1)
    .test("range", "Cant be higher than 10", (val) => Number(val) <= 10),
  piua: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+(\.[0-9]+)?$/, "Must be only digits")
    .test("range", "Cant be lower than 0.1", (val) => Number(val) >= 0.1)
    .test("range", "Cant be higher than 10", (val) => Number(val) <= 10),
})

export default CerebroPlacentalSchema
