import React, {useState} from "react"
import DropDownHeader from "../../../components/DropDown/Header"
import {StyledCVT, StyledBodyContainer} from "./styles"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import WeeksDaysInputs from "../../../components/WeeksDaysInputs"
import DropDownBody from "../../../components/DropDown/Body"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import Form from "../../../components/Form"
import CVTSchema from "../../../components/Form/Validations/CVT"
import {useStore} from "../../../store"
import {AnemiaActionKind} from "../../../store/Reducers/Modules/anemia"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {calcVolume} from "../../../utils/algorithms/anemia"
import {CVTInitStateType} from "../../../store/Initials/anemia"

const CVT = () => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(CVTSchema),
  })

  const submitHandler = (values: FieldValues) => {
    calcVolume({volumeValues: values as CVTInitStateType, dispatch: updateOutputs})
  }

  const updateOutputs = (volume: string) => {
    dispatch({
      action: AnemiaActionKind.UPDATE_CVT,
      payload: {...state.herniaValues.pos, volume: volume},
    })
  }

  return (
    <StyledCVT>
      <DropDownHeader
        nested
        name={t("anemia.cvt.header")}
        active={active}
        toggle={() => setActive(!active)}
      />
      {active && (
        <DropDownBody white>
          <StyledBodyContainer>
            <Form handler={handleSubmit(submitHandler)}>
              <InColumnLabel>
                <BasicLabel name="time">{t("anemia.cvt.ga")}</BasicLabel>

                <WeeksDaysInputs
                  errors={errors}
                  register={register}
                  weeks={state.anemiaValues.cvt.weeks}
                  setWeeks={(value) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CVT,
                      payload: {...state.anemiaValues.cvt, weeks: value},
                    })
                  }
                  days={state.anemiaValues.cvt.days}
                  setDays={(value) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CVT,
                      payload: {...state.anemiaValues.cvt, days: value},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="donoHema">{t("anemia.cvt.donoHema")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="donoHema"
                  type="tel"
                  value={state.anemiaValues.cvt.donoHema}
                  setValue={(v) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CVT,
                      payload: {...state.anemiaValues.cvt, donoHema: v},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="desiredHema">
                  {t("anemia.cvt.desiredHema")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="desiredHema"
                  type="tel"
                  value={state.anemiaValues.cvt.desiredHema}
                  setValue={(v) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CVT,
                      payload: {...state.anemiaValues.cvt, desiredHema: v},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="observedHema">
                  {t("anemia.cvt.observedHema")}
                </BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="observedHema"
                  type="tel"
                  value={state.anemiaValues.cvt.observedHema}
                  setValue={(v) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CVT,
                      payload: {...state.anemiaValues.cvt, observedHema: v},
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <BasicLabel name="expectedPSV">{t("anemia.cvt.volume")}</BasicLabel>
                <BasicOutput value={state.anemiaValues.cvt.volume} length={100} />
              </InLineLabel>
            </Form>
          </StyledBodyContainer>
        </DropDownBody>
      )}
    </StyledCVT>
  )
}

export default CVT
