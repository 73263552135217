import styled from "styled-components"
import {ReactComponent as Calendar} from "../../assets/svgs/Calendar.svg"
import {ReactComponent as CalendarDisabled} from "../../assets/svgs/CalendarDisabled.svg"

const styles = `
z-index: 1;
position: absolute;
right: 10%;
top: 20%;
`

export const StyledCalendar = styled(Calendar)`
  ${styles}
`

export const StyledCalendarDisabled = styled(CalendarDisabled)`
  ${styles}
`
