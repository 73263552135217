import styled, {css} from "styled-components"

export const StyledLHR = styled.div(() => css``)

export const StyledBodyContainer = styled.div`
  padding: 20px;
`
export const StyledGreyBackground = styled.div(
  ({theme: {colors, weights, typography}}) => css`
    background: ${colors.greyBackground};
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 7px;
    font-weight: ${weights.weak};
    font-size: ${typography.small};
    color: ${colors.activeText};
    line-height: 16px;
    width: 96%;
    margin-left: 2%;
    border-top: 1px solid #888888;
    border-right: 2px solid ${colors.greyBackground};
  `
)

export const StyledTitle = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.regular};
    font-size: ${typography.medium};
    line-height: 19px;
  `
)
