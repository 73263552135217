import React, {useEffect, useRef, useState} from "react"
import {
  StyledSelect,
  StyledSelectContainer,
  StyledOption,
  StyledOptionContainer,
  StyledErrorMessage,
  StyledOptionValue,
} from "./styles"
import {useTranslation} from "react-i18next"
import {UpArrow, DownArrow} from "../Arrows"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"
import {ErrorMessage} from "@hookform/error-message"

export type SelectProps = {
  data: [{[key: string]: number}]
  value: {[key: string]: number}
  setValue: (value: {[key: string]: number}) => void
  register: UseFormRegister<FieldValues>
  name: string
  setRefValue: (
    name: string,
    value: number,
    config?: Record<string, unknown>
  ) => void
  errors?: FieldErrorsImpl<FieldValues>
}

const Select = ({
  errors,
  data,
  value,
  setValue,
  register,
  name,
  setRefValue,
}: SelectProps): JSX.Element => {
  const {t} = useTranslation("translation")
  const [dropedDown, setDropedDown] = useState<boolean>(false)
  const {ref, ...restOfRegisterProps} = register(name)
  const sel = Object.keys(value)[0]

  const setSelection = (value: {[key: string]: number}) => {
    setValue(value)
    setRefValue(name, Object.values(value)[0])
    setDropedDown(false)
  }

  const node = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if (node.current !== null && !node.current.contains(e.target as Node)) {
      setDropedDown(false)
    }
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const error = errors == null ? false : errors[`${name}`] != null
  return (
    <StyledSelectContainer ref={node}>
      {
        <StyledOptionContainer>
          <StyledOption
            zindex={9}
            errors={error}
            {...restOfRegisterProps}
            ref={ref}
            border={"both"}
            onClick={() => setDropedDown(true)}
            value={value}>
            <StyledOptionValue>{sel}</StyledOptionValue> <DownArrow />
          </StyledOption>
          {errors && (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({message}) => (
                <StyledErrorMessage>{message}</StyledErrorMessage>
              )}
            />
          )}
        </StyledOptionContainer>
      }
      {dropedDown && (
        <StyledSelect>
          <StyledOption
            selected={false}
            onClick={() => setDropedDown(false)}
            zindex={10}>
            {t("select.choose")}
            <UpArrow />
          </StyledOption>

          {Object.keys(data).map((value, index) => (
            <StyledOption
              zindex={10}
              key={value}
              selected={value == sel}
              onClick={() => setSelection({[value]: Object(data)[`${value}`]})}
              // no me odien
              border={
                index == 0 ? "top" : index == data.length - 1 ? "bottom" : "none"
              }>
              {value}
            </StyledOption>
          ))}
        </StyledSelect>
      )}
    </StyledSelectContainer>
  )
}

export default Select
