import React from "react"
import {StyledCalendar, StyledCalendarDisabled} from "./styles"

export type CalendarProps = {
  toggle: () => void
  disabled?: boolean
}

const Calendar = ({toggle, disabled = false}: CalendarProps): JSX.Element => {
  if (!disabled) {
    return <StyledCalendar onClick={toggle} />
  } else {
    return <StyledCalendarDisabled />
  }
}

export default Calendar
