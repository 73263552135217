import React, {useReducer} from "react"
import {StyledFetalGrowth} from "./styles"
import {useTranslation} from "react-i18next"
import RadioButton from "../../components/RadioButton"
import {
  FetalGrowthInitState,
  FetalGrowthInitStateType,
} from "../../utils/StateInitials/FetalGrowth"
import WeeksDaysInputs from "../../components/WeeksDaysInputs"
import BasicInput from "../../components/BasicInput"
import Line from "../../components/Line"
import {InColumnLabel} from "../../components/LabelContainer"
import BasicLabel from "../../components/BasicLabel"
import GenderRadio, {genders} from "../../components/GenderRadio"
import {StyledLineButtons} from "../MonochronicTwins/DiagnosticAlgorithm/TwinsAlgorithm/styles"
import ClearValues from "../../components/ClearValues"
import CalculateButton from "../../components/CalculateButton"
import {useForm} from "react-hook-form"

export type FGRadio = "gestationalAge" | "lastMenstrualPeriod" | ""

const FetalGrowth = () => {
  const {t} = useTranslation("translation")
  const {register} = useForm()

  const [values, setValues] = useReducer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: FetalGrowthInitStateType, newValues: any) => ({
      ...values,
      ...newValues,
    }),
    FetalGrowthInitState
  )

  const clearValues = () => {
    setValues(FetalGrowthInitState)
  }

  return (
    <StyledFetalGrowth>
      <InColumnLabel>
        <RadioButton
          register={register}
          value="gestationalAge"
          text={t("fetalGrowth.GARadio")}
          checked={values.FGRadio == "gestationalAge"}
          setValue={() => setValues({FGRadio: "gestationalAge"})}
          id={t("fetalGrowth.GARadio") + "FGRadio"}
          name="FGRadio"
        />
        <WeeksDaysInputs
          register={register}
          weeks={values.weeks}
          disabled={!(values.FGRadio == "gestationalAge")}
          setWeeks={(v) => setValues({weeks: v})}
          days={values.days}
          setDays={(v) => setValues({days: v})}
        />
      </InColumnLabel>
      <InColumnLabel>
        <RadioButton
          register={register}
          value="lastMenstrualPeriod"
          text={t("fetalGrowth.LMPRadio")}
          checked={values.FGRadio == "lastMenstrualPeriod"}
          setValue={() => setValues({FGRadio: "lastMenstrualPeriod"})}
          id={t("fetalGrowth.LMPRadio") + "FGRadio"}
          name="FGRadio"
        />
        <BasicInput
          register={register}
          disabled={!(values.FGRadio == "lastMenstrualPeriod")}
          name="lmp"
          length={96}
          type="date"
          value={values.lmp}
          setValue={(v) => setValues({lmp: v})}
        />
      </InColumnLabel>

      <Line top={20} bottom={20} />

      <InColumnLabel>
        <BasicLabel name="efw">{t("fetalGrowth.efw")}</BasicLabel>
        <BasicInput
          register={register}
          name="efw"
          length={96}
          type="tel"
          value={values.efw}
          setValue={(v) => setValues({efw: v})}
        />
      </InColumnLabel>

      <InColumnLabel>
        <BasicLabel name="FGGender">{t("fetalGrowth.genderLabel")}</BasicLabel>
        <GenderRadio
          register={register}
          value={values.gender}
          setValue={(v) => setValues({gender: v as genders})}
          label="FGGender"
        />
      </InColumnLabel>
      <StyledLineButtons>
        <ClearValues onClick={() => clearValues()} />
        <CalculateButton onClick={() => console.log(values)} />
      </StyledLineButtons>
    </StyledFetalGrowth>
  )
}

export default FetalGrowth
