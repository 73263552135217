import React from "react"
import {FieldValues, UseFormRegister} from "react-hook-form"
import {StyledRadio, StyledSpan, StyledLabel} from "./styles"

type RadioButtonProps = {
  value: string
  setValue: (value: string) => void
  id: string
  name: string
  checked: boolean
  text: string
  register: UseFormRegister<FieldValues>
}

const RadioButton = ({
  value,
  setValue,
  id,
  name,
  checked = false,
  text,
  register,
}: RadioButtonProps) => {
  const {ref, ...restOfRegisterProps} = register(name)
  return (
    <>
      <StyledLabel htmlFor={id}>
        <StyledRadio
          {...restOfRegisterProps}
          type="radio"
          id={id}
          ref={ref}
          checked={checked}
          name={name}
          value={value}
          onClick={() => setValue(value)}
        />
        <StyledSpan>{text}</StyledSpan>
      </StyledLabel>
    </>
  )
}

export default RadioButton
