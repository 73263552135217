import styled, {css} from "styled-components"
import {StyledOption} from "../../../../components/Select/styles"
import {StyledClearValues} from "../../../../components/ClearValues/styles"
import {StyledCalculateButton} from "../../../../components/CalculateButton/styles"
import {StyledBasicOutput} from "../../../../components/BasicOutput/styles"

export const StyledTwinsAlgorithm = styled.div(
  () => css`
    width: 100%;
    ${StyledOption} {
      width: 90%;
    }
    ${StyledBasicOutput} {
      width: 60%;
      margin-right: -22%;
    }

    ${StyledClearValues} {
      width: fit-content;
      margin: auto;
    }
  `
)

export const StyledLabel = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.regular};
    font-size: ${typography.medium};
    text-align: center;
    width: 100%;
  `
)

export const StyledLineButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-left: 5%;

  ${StyledClearValues} {
    min-width: fit-content;
    padding-top: 30px;
  }

  ${StyledCalculateButton} {
    margin-top: 1px;
    width: 60%;
  }
`

export const StyledColumnContainer = styled.div`
  margin-left: 1%;
  position: relative;
  width: 100%;
  height: 720px;
  margin-bottom: 50px;

  input {
    z-index: 5;
  }
`
