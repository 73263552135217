import styled from "styled-components"
import {ReactComponent as Gestational} from "../../assets/svgs/Gestational.svg"
import {ReactComponent as Anemia} from "../../assets/svgs/Anemia.svg"
import {ReactComponent as Doppler} from "../../assets/svgs/Doppler.svg"
import {ReactComponent as FG} from "../../assets/svgs/FG.svg"
import {ReactComponent as Hernia} from "../../assets/svgs/Hernia.svg"
import {ReactComponent as MT} from "../../assets/svgs/MT.svg"
import {ReactComponent as Preeclampsia} from "../../assets/svgs/Preeclampsia.svg"
import {ReactComponent as Cardiac} from "../../assets/svgs/Cardiac.svg"

const styles = `
z-index: 1;
margin: 0;
padding: 0;
width: 75%;
height: 50%;
`

export const StyledGestational = styled(Gestational)`
  ${styles}
`

export const StyledAnemia = styled(Anemia)`
  ${styles}
`
export const StyledDoppler = styled(Doppler)`
  ${styles}
`
export const StyledFG = styled(FG)`
  ${styles}
`
export const StyledHernia = styled(Hernia)`
  ${styles}
`
export const StyledMT = styled(MT)`
  ${styles}
`

export const StyledCardiac = styled(Cardiac)`
  ${styles}
`
export const StyledPreeclampsia = styled(Preeclampsia)`
  ${styles}
`
