import "./i18n"
import Theme from "./Theme"
import {State} from "./store"
import Menu from "./modules/Menu"

const App = (): JSX.Element => {
  return (
    <Theme>
      <State>
        <Menu />
      </State>
    </Theme>
  )
}

export default App
