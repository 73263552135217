import React from "react"
import {StyledDropDownHeader, StyledP, StyledSpan} from "./styles"
import {UpArrow, DownArrow} from "../../Arrows"

type DropDownHeaderProps = {
  name: string
  active: boolean
  toggle: () => void
  nested?: boolean
}

// El toggle deberia dejarlo solo en la fecha? o en todo el dropdown
const DropDownHeader = ({
  name,
  active,
  toggle,
  nested = false,
}: DropDownHeaderProps): JSX.Element => {
  return (
    <StyledDropDownHeader onClick={toggle} active={active}>
      <StyledP nested={nested}>{name}</StyledP>
      <StyledSpan onClick={toggle}>
        {active ? <UpArrow white /> : <DownArrow white />}
      </StyledSpan>
    </StyledDropDownHeader>
  )
}

export default DropDownHeader
