import * as yup from "yup"

const MultiplePercentileSchema = yup.object({
  genderLarger: yup.string().nullable().required("Required"),
  genderSmaller: yup.string().nullable().required("Required"),
  efwLarger: yup.string().required("Required"),
  efwSmaller: yup.string().required("Required"),
  weeks: yup.string().required("Required"),
  days: yup.string().required("Required"),
})

export default MultiplePercentileSchema
