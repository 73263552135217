import buttons from "./Buttons/index.json"
import monochronicTwins from "./MonochronicTwins/index.json"
import select from "./Select/index.json"

type translation = {
  [key: string]: (string | translation) 
}

type Translations = Record<string, translation>

const translation: Translations = {
  buttons,
  select,
  monochronicTwins,
}

export default translation
