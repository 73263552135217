export const typography: Typography = {
  small: "14px",
  medium: "16px",
  intermedium: "18px",
  large: "20px",
  xlarge: "24px",
  xxlarge: "32px",
  xxxlarge: "40px",
}

export type Typography = Record<string, string>
