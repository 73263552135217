import {createContext, Dispatch, useReducer, useContext} from "react"
import {storeInitials} from "./Initials"
import {AnemiaInitStateType} from "./Initials/anemia"
import {DopplerInitStateType} from "./Initials/doppler"
import {GestationalInitStateType} from "./Initials/gestational"
import {HerniaInitStateType} from "./Initials/hernia"
import {MonochronicTwinsInitStateType} from "./Initials/monochronicTwins"
import {PreeclampsiaInitStateType} from "./Initials/preeclampsia"
import reducers from "./Reducers"

export type stateType = {
  currentModule: string
  dopplerValues: DopplerInitStateType
  preeclampsiaValues: PreeclampsiaInitStateType
  monochronicTwinsValues: MonochronicTwinsInitStateType
  herniaValues: HerniaInitStateType
  gestationalValues: GestationalInitStateType
  anemiaValues: AnemiaInitStateType
}

type StringOrNumber = string | number

type ObjectWithStringOrNumber = {[key: string]: StringOrNumber}

type ObjectWithObject = {[key: string]: ObjectWithStringOrNumber}

interface StateContextInterface {
  state: stateType
  dispatch: Dispatch<{
    action: string
    payload: string | ObjectWithObject | ObjectWithStringOrNumber
  }>
}

export const stateContext = createContext({} as StateContextInterface)
export const useStore = () => useContext(stateContext)

type Props = {
  children?: React.ReactNode
}

export const State: React.FC<Props> = ({children}: Props) => {
  const [state, dispatch] = useReducer(reducers, storeInitials)
  const contextValue = {dispatch, state}

  return (
    <stateContext.Provider value={contextValue}>{children} </stateContext.Provider>
  )
}
