import React from "react"
import BasicInput from "../../../components/BasicInput"
import BasicLabel from "../../../components/BasicLabel"
import {InColumnLabel} from "../../../components/LabelContainer"
import {StyledSectionHeader} from "../styles"
import {useTranslation} from "react-i18next"
import {useStore} from "../../../store"
import {PreeclampsiaActionKind} from "../../../store/Reducers/Modules/preeclampsia"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"

type BDProps = {
  register: UseFormRegister<FieldValues>
  errors?: FieldErrorsImpl<FieldValues>
}

const BiometricData = ({register, errors}: BDProps) => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  return (
    <>
      <StyledSectionHeader>
        {t("preeclampsia.biometricDataHeader")}
      </StyledSectionHeader>

      <InColumnLabel>
        <BasicLabel name="age">{t("preeclampsia.age")}</BasicLabel>
        <BasicInput
          errors={errors}
          register={register}
          name="age"
          type="tel"
          value={state.preeclampsiaValues.biometric.age}
          length={93}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_BIOMETRIC,
              payload: {...state.preeclampsiaValues.biometric, age: v},
            })
          }
        />
      </InColumnLabel>

      <InColumnLabel>
        <BasicLabel name="height">{t("preeclampsia.height")}</BasicLabel>
        <BasicInput
          errors={errors}
          register={register}
          name="height"
          type="tel"
          value={state.preeclampsiaValues.biometric.height}
          length={93}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_BIOMETRIC,
              payload: {...state.preeclampsiaValues.biometric, height: v},
            })
          }
        />
      </InColumnLabel>

      <InColumnLabel>
        <BasicLabel name="weight">{t("preeclampsia.weight")}</BasicLabel>
        <BasicInput
          errors={errors}
          register={register}
          name="weight"
          type="tel"
          value={state.preeclampsiaValues.biometric.weight}
          length={93}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_BIOMETRIC,
              payload: {...state.preeclampsiaValues.biometric, weight: v},
            })
          }
        />
      </InColumnLabel>
    </>
  )
}

export default BiometricData
