import React, {useEffect, useRef} from "react"
import {useStore} from "../../store"
import {StateActionKind, StateModuleKind} from "../../store/Reducers/Modules/menu"
import Hamburguer from "../Hamburguer"
import Line from "../Line"
import {
  HamburguerContainer,
  StyledDrawer,
  StyledItem,
  StyledDrawerContainer,
} from "./styles"

type DrawerProps = {
  isVisible: boolean
  toggle: () => void
  close: () => void
}

const Drawer = ({isVisible, toggle, close}: DrawerProps) => {
  const {dispatch} = useStore()
  const onClick = () => {
    dispatch({
      action: StateActionKind.CHANGE_MODULE,
      payload: StateModuleKind.REFERENCES,
    })
  }
  const node = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if (node.current !== null && !node.current.contains(e.target as Node)) {
      close()
    }
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = isVisible ? "hidden" : "auto"

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isVisible])

  return (
    <StyledDrawerContainer isVisible={isVisible}>
      <StyledDrawer isVisible={isVisible} ref={node}>
        <HamburguerContainer isVisible={isVisible}>
          <Hamburguer toggle={toggle} />
        </HamburguerContainer>
        <StyledItem onClick={onClick} isVisible={isVisible}>
          {StateModuleKind.REFERENCES}
        </StyledItem>
        {isVisible && <Line width={83} white top={20} />}
      </StyledDrawer>
    </StyledDrawerContainer>
  )
}

export default Drawer
