export type CerebralDopplerInitStateType = {
  weeks: string
  days: string
  mca: string
  expectedPSV: string
  mom: string
  anemiaLevel: string
}

export const CerebralDopplerInitState = {
  weeks: "",
  days: "",
  mca: "",
  expectedPSV: "",
  mom: "",
  anemiaLevel: "",
}

export type CVTInitStateType = {
  weeks: string
  days: string
  donoHema: string
  desiredHema: string
  observedHema: string
  volume: string
}

export const CVTInitState = {
  weeks: "",
  days: "",
  donoHema: "",
  desiredHema: "",
  observedHema: "",
  volume: "",
}

export type AnemiaInitStateType = {
  cerebralDoppler: CerebralDopplerInitStateType
  cvt: CVTInitStateType
}

const anemiaInitState = {
  anemiaValues: {
    cerebralDoppler: "",
    cvt: "",
  },
}

export default anemiaInitState
