import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import EN from "./Locales/En"
import ES from "./Locales/Es"
import LanguageDetector from "i18next-browser-languagedetector"

const resources = {
  en: {
    translation: EN,
  },
  es: {
    translation: ES,
  },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
