import styled, {css} from "styled-components"
import {StyledClearValues} from "../../components/ClearValues/styles"
import {StyledDropDownBody} from "../../components/DropDown/Body/styles"
import {
  StyledLabelContainer,
  StyledRestContainer,
} from "../../components/LabelContainer/styles"

export const StyledDoppler = styled.div`
  width: 100%;

  ${StyledLabelContainer} {
    margin: 0;
    padding-left: 3%;
    width: 97%;
  }
  ${StyledRestContainer} {
    margin: 0;
    padding-left: 2%;
    width: 98%;
  }

  ${StyledClearValues} {
    margin-left: 70%;
  }
`

export const StyledConfinedSpace = styled.div`
  ${StyledDropDownBody} {
    padding: 5%;
    width: 86%;
  }
`

export const StyledGAContainer = styled.div(
  ({theme: {colors}}) => css`
    background-color: ${colors.primaryDropeddownBackground};
    padding: 5%;
    padding-bottom: 10px;
    border-radius: 8px;
    width: 86%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;

    ${StyledClearValues} {
      margin-right: 4%;
      white-space: nowrap;
      align-self: flex-end;
    }
  `
)
