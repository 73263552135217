import {LHRInitStateType, POStInitStateType} from "../../store/Initials/hernia"

type OELHRFuncType = {
  l: number
  t: number
  c: number
  tot: number
  first: number
  second: number
  third: number
}

export const OELHRFunc = ({l, t, c, tot, first, second, third}: OELHRFuncType) => {
  return Math.round(
    ((l * t) / c / (-first * tot * tot + second * tot - third)) * 100
  )
}

type LHRProps = {
  lhrValues: LHRInitStateType
  dispatch: (lhr: string, percentage: string) => void
  side: string
}

export const LHRCalc = ({lhrValues, dispatch, side}: LHRProps) => {
  const longitudinal = Number(lhrValues.longitudinal)
  const transversal = Number(lhrValues.transversal)
  const circumference = Number(lhrValues.circumference)
  const ltc = (longitudinal * transversal) / circumference
  type divisorType = 3 | 4 | 5 | 6 | 7 | 8
  const divisorArray = {
    3: 10,
    4: 100,
    5: 1000,
    6: 10000,
    7: 100000,
    8: 100000,
  }
  const weeks = Number(lhrValues.weeks)
  const days = Number(lhrValues.days)
  const totalWeeks = weeks + days / 7
  if (side == "left") {
    const oeLHR = OELHRFunc({
      l: longitudinal,
      t: transversal,
      c: circumference,
      tot: totalWeeks,
      first: 0.0048,
      second: 0.3995,
      third: 3.4802,
    })
    const index = String(oeLHR).length as divisorType
    const divisor = divisorArray[index]
    // Left OE LHR result
    const dividedOELHR = oeLHR / divisor
    const error = index != undefined ? dividedOELHR : "Syntax error"
    console.log(index, divisor, error)

    dispatch(`${ltc}`, `${error}`)
  } else {
    const oeLHR = OELHRFunc({
      l: longitudinal,
      t: transversal,
      c: circumference,
      tot: totalWeeks,
      first: 0.0042,
      second: 0.3043,
      third: 2.5957,
    })
    const index = String(oeLHR).length as divisorType
    const divisor = divisorArray[index]
    // Right OE LHR result
    const dividedOELHR = oeLHR / divisor

    dispatch(`${ltc}`, `${dividedOELHR}`)
  }
}
type POSProps = {
  posValues: POStInitStateType
  dispatch: (expectedSurvival: string) => void
}

export const POSCalc = ({posValues, dispatch}: POSProps) => {
  const radio = Number(posValues.lungToHead)
  const side = posValues.side
  let res = 0
  if (side == "left") {
    const liverUp = Number(posValues.liverUp)
    if (radio < 26) {
      res = 30 - 15 * liverUp
    } else if (radio < 36) {
      res = 65 - 10 * liverUp
    } else if (radio < 46) {
      res = 75 - 10 * liverUp
    } else {
      res = 90
    }
  } else {
    if (radio < 30) {
      res = 5
    } else if (radio < 46) {
      res = 20
    } else if (radio < 56) {
      res = 50
    } else {
      res = 70
    }
  }
  dispatch(`${res}`)
}
