import React from "react"
import {StyledCalculateButton} from "./styles"
import {useTranslation} from "react-i18next"

type CalculateButtonProps = {
  onClick?: () => void
  length?: number
}

const CalculateButton = ({
  onClick,
  length = 96,
}: CalculateButtonProps): JSX.Element => {
  const {t} = useTranslation("translation")

  return (
    // type submit reloads the site, either prevent default or dont use it
    <StyledCalculateButton onClick={onClick} length={length} type="submit">
      {t("buttons.calculate")}
    </StyledCalculateButton>
  )
}

export default CalculateButton
