import styled from "styled-components"

export const StyledMenu = styled.div`
  & svg {
    margin-left: 2%;
  }
`

export const StyledButtonList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
`
