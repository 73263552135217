import styled, {css} from "styled-components"
import {StyledOption, StyledSelect} from "../../components/Select/styles"
import {
  StyledRestContainer,
  StyledLabelContainer,
  StyledInlineContainer,
} from "../../components/LabelContainer/styles"

export const StyledPreeclampsia = styled.div(
  ({theme: {colors}}) => css`
    display: flex;
    flex-direction: column;
    width: 94%;
    margin-left: 3%;
    background: ${colors.whiteIshBackground};
    border-radius: 8px;
    padding-bottom: 20px;

    ${StyledOption} {
      width: 83%;
      margin-left: -20%;
    }
    ${StyledSelect} {
      width: 100%;
    }
  `
)

export const StyledInLineLabel = styled(StyledInlineContainer)`
  ${StyledRestContainer} {
    margin-left: 12px;
  }
  ${StyledLabelContainer} {
    margin-left: 12px;
  }
`

export const StyledHeader = styled.p(
  ({theme: {weights, typography}}) => css`
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    line-height: 23px;
    text-align: center;
  `
)

export const StyledSectionHeader = styled.p(
  ({theme: {colors, weights, typography}}) => css`
    font-weight: ${weights.weak};
    font-size: ${typography.large};
    line-height: 23px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 5px;
    background-color: ${colors.headerGreyBackground};
  `
)

export const StyledNothing = styled.div``
