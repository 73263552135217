import styled, {css} from "styled-components"

const ContainerStyles = css`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: stetch;
`

export const StyledInlineContainer = styled.div(
  () => css`
    ${ContainerStyles}
    flex-direction: row;
  `
)

export const StyledLabelContainer = styled.div(
  () => css`
    display: flex;
    width: 100%;
    margin-left: 12px;
    padding-bottom: 10px;
  `
)

export const StyledRestContainer = styled.div`
  width: 100%;
  margin-left: 12px;
  padding-bottom: 10px;
`

export const StyledInColumnContainer = styled.div`
  ${ContainerStyles}
  flex-direction: column;
`
