import styled from "styled-components"

import {
  StyledCalendar,
  StyledCalendarDisabled,
} from "../../components/Calendar/styles"

export const StyledFetalGrowth = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 5%;

  ${StyledCalendar} {
    margin-top: 12px;
  }
  ${StyledCalendarDisabled} {
    margin-top: 12px;
  }
`
