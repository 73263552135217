import React, {useState} from "react"
import {StyledLast} from "./styles"
import DropDownHeader from "../../../../components/DropDown/Header"
import DropDownBody from "../../../../components/DropDown/Body"
import {InColumnLabel} from "../../../../components/LabelContainer"
import BasicInput from "../../../../components/BasicInput"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledBlackText, StyledGreyText} from "../../../../components/Texts/styles"
import BasicLabel from "../../../../components/BasicLabel"
import Form from "../../../../components/Form"
import LPM from "../../../../components/Form/Validations/LPM"
import {useTranslation} from "react-i18next"
import {useStore} from "../../../../store"
import {GestationalActionKind} from "../../../../store/Reducers/Modules/gestational"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const Last = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(LPM),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (ga: string, eed: string) => {
    dispatch({
      action: GestationalActionKind.UPDATE_LMP,
      payload: {
        ...state.gestationalValues.lmp,
        ga: ga,
        eed: eed,
      },
    })
  }

  // Deberia tener un useEffect para que cada vez que confirmedLPM cambie se realize un submit del formulario
  return (
    <StyledLast>
      <DropDownHeader
        name={t("gestational.dating.lpm.header")}
        active={active}
        nested
        toggle={() => setActive(!active)}></DropDownHeader>

      {active && (
        <DropDownBody white nested>
          <Form handler={handleSubmit(submitHandler)}>
            <InColumnLabel>
              <BasicLabel name="ConfirmedLPM">
                {t("gestational.dating.lpm.confirmedInputLabel")}
              </BasicLabel>
              <BasicInput
                errors={errors}
                register={register}
                type="date"
                name="ConfirmedLPM"
                length={96}
                value={state.gestationalValues.lmp.confirmedLPM}
                setValue={(value) =>
                  dispatch({
                    action: GestationalActionKind.UPDATE_LMP,
                    payload: {...state.gestationalValues.lmp, confirmedLPM: value},
                  })
                }
              />
            </InColumnLabel>

            <BasicOutput value={state.gestationalValues.lmp.ga} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.lpm.gaOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.lpm.egOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>

            <BasicOutput value={state.gestationalValues.lmp.eed} length={100}>
              <InColumnLabel>
                <StyledBlackText>
                  {t("gestational.dating.lpm.eedOutput")}
                </StyledBlackText>
                <StyledGreyText>
                  {t("gestational.dating.lpm.fppOutput")}
                </StyledGreyText>
              </InColumnLabel>
            </BasicOutput>
          </Form>
        </DropDownBody>
      )}
    </StyledLast>
  )
}

export default Last
