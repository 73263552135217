import {genders} from "../../../components/GenderRadio"
import {FGRadio} from "../../../modules/FetalGrowth"

export type FetalGrowthInitStateType = {
  FGRadio: FGRadio
  weeks: string
  days: string
  lmp: string
  efw: string
  gender: genders
}

export const FetalGrowthInitState = {
  FGRadio: "",
  weeks: "",
  days: "",
  lmp: "",
  efw: "",
  gender: "",
}
