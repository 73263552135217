import styled, {css} from "styled-components"

export const StyledHernia = styled.div(() => css``)

export const StyledHeader = styled.p(
  ({theme: {weights, typography, colors}}) => css`
    font-weight: ${weights.regular};
    font-size: ${typography.large};
    font-style: normal;
    line-height: 26px;
    width: 70%;
    padding-left: 15%;
    letter-spacing: -0.02em;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.activeText};
  `
)

export const StyledTab = styled.div(
  ({theme: {colors}}) => css`
    display: flex;
    width: 90%;
    background: ${colors.leftRightTabBackground};
    margin-left: 5%;
    border-radius: 47px;
  `
)
