import React from "react"
import BasicInput from "../../../components/BasicInput"
import BasicLabel from "../../../components/BasicLabel"
import {InColumnLabel} from "../../../components/LabelContainer"
import {StyledInLineLabel, StyledSectionHeader} from "../styles"
import {useTranslation} from "react-i18next"
import {useStore} from "../../../store"
import {PreeclampsiaActionKind} from "../../../store/Reducers/Modules/preeclampsia"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"

type FTDProps = {
  register: UseFormRegister<FieldValues>
  errors?: FieldErrorsImpl<FieldValues>
}

const FTD = ({register, errors}: FTDProps) => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  return (
    <>
      <StyledSectionHeader>
        {t("preeclampsia.firstTrimesterDataHeader")}
      </StyledSectionHeader>

      <InColumnLabel>
        <BasicLabel name="crl">{t("preeclampsia.crl")}</BasicLabel>
        <BasicInput
          errors={errors}
          register={register}
          name="crl"
          type="tel"
          value={state.preeclampsiaValues.ftd.crl}
          length={93}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_FTD,
              payload: {...state.preeclampsiaValues.ftd, crl: v},
            })
          }
        />
      </InColumnLabel>

      <StyledInLineLabel>
        <InColumnLabel>
          <BasicLabel name="systolicBP">{t("preeclampsia.systolicBP")}</BasicLabel>
          <BasicInput
            errors={errors}
            register={register}
            name="systolicBP"
            type="tel"
            value={state.preeclampsiaValues.ftd.systolicBP}
            length={86}
            setValue={(v) =>
              dispatch({
                action: PreeclampsiaActionKind.UPDATE_FTD,
                payload: {...state.preeclampsiaValues.ftd, systolicBP: v},
              })
            }
          />
        </InColumnLabel>

        <InColumnLabel>
          <BasicLabel name="diastolicBP">{t("preeclampsia.diastolicBP")}</BasicLabel>
          <BasicInput
            errors={errors}
            register={register}
            name="diastolicBP"
            type="tel"
            value={state.preeclampsiaValues.ftd.diastolicBP}
            length={86}
            setValue={(v) =>
              dispatch({
                action: PreeclampsiaActionKind.UPDATE_FTD,
                payload: {...state.preeclampsiaValues.ftd, diastolicBP: v},
              })
            }
          />
        </InColumnLabel>
      </StyledInLineLabel>

      <InColumnLabel>
        <BasicLabel name="ipmaut">{t("preeclampsia.ipmaut")}</BasicLabel>
        <BasicInput
          errors={errors}
          register={register}
          name="ipmaut"
          type="tel"
          value={state.preeclampsiaValues.ftd.ipmaut}
          length={93}
          setValue={(v) =>
            dispatch({
              action: PreeclampsiaActionKind.UPDATE_FTD,
              payload: {...state.preeclampsiaValues.ftd, ipmaut: v},
            })
          }
        />
      </InColumnLabel>
    </>
  )
}

export default FTD
