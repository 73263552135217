import React, {useState} from "react"
import DropDownHeader from "../../../components/DropDown/Header"
import {StyledCerebralDoppler, StyledBodyContainer} from "./styles"
import {useTranslation} from "react-i18next"
import {InColumnLabel, InLineLabel} from "../../../components/LabelContainer"
import BasicLabel from "../../../components/BasicLabel"
import WeeksDaysInputs from "../../../components/WeeksDaysInputs"
import DropDownBody from "../../../components/DropDown/Body"
import BasicInput from "../../../components/BasicInput"
import CalculateButton from "../../../components/CalculateButton"
import BasicOutput from "../../../components/BasicOutput"
import Form from "../../../components/Form"
import CerebralDopplerSchema from "../../../components/Form/Validations/CerebralDoppler"
import {useStore} from "../../../store"
import {AnemiaActionKind} from "../../../store/Reducers/Modules/anemia"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {calcCerebralDoppler} from "../../../utils/algorithms/anemia"
import {CerebralDopplerInitStateType} from "../../../store/Initials/anemia"

const CerebralDoppler = () => {
  const {t} = useTranslation("translation")
  const [active, setActive] = useState(false)
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(CerebralDopplerSchema),
    reValidateMode: "onChange" || "onBlur",
  })

  const submitHandler = (values: FieldValues) => {
    calcCerebralDoppler({
      cerebralDopplerValues: values as CerebralDopplerInitStateType,
      dispatch: updateOutputs,
    })
  }

  const updateOutputs = (expectedPSV: string, mom: string, anemiaLevel: string) => {
    dispatch({
      action: AnemiaActionKind.UPDATE_CEREBRAL_DOPPLER,
      payload: {
        ...state.anemiaValues.cerebralDoppler,
        expectedPSV: expectedPSV,
        mom: mom,
        anemiaLevel: anemiaLevel,
      },
    })
  }

  return (
    <StyledCerebralDoppler>
      <DropDownHeader
        nested
        name={t("anemia.cerebralDoppler.header")}
        active={active}
        toggle={() => setActive(!active)}
      />
      {active && (
        <DropDownBody white>
          <StyledBodyContainer>
            <Form handler={handleSubmit(submitHandler)}>
              <InColumnLabel>
                <BasicLabel name="time">{t("anemia.cerebralDoppler.ga")}</BasicLabel>

                <WeeksDaysInputs
                  errors={errors}
                  register={register}
                  weeks={state.anemiaValues.cerebralDoppler.weeks}
                  setWeeks={(value) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CEREBRAL_DOPPLER,
                      payload: {...state.anemiaValues.cerebralDoppler, weeks: value},
                    })
                  }
                  days={state.anemiaValues.cerebralDoppler.days}
                  setDays={(value) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CEREBRAL_DOPPLER,
                      payload: {...state.anemiaValues.cerebralDoppler, days: value},
                    })
                  }
                />
              </InColumnLabel>

              <InColumnLabel>
                <BasicLabel name="mca">{t("anemia.cerebralDoppler.mca")}</BasicLabel>
                <BasicInput
                  errors={errors}
                  register={register}
                  length={96}
                  name="mca"
                  type="tel"
                  value={state.anemiaValues.cerebralDoppler.mca}
                  setValue={(v) =>
                    dispatch({
                      action: AnemiaActionKind.UPDATE_CEREBRAL_DOPPLER,
                      payload: {...state.anemiaValues.cerebralDoppler, mca: v},
                    })
                  }
                />
              </InColumnLabel>

              <CalculateButton />

              <InLineLabel>
                <BasicLabel name="expectedPSV">
                  {t("anemia.cerebralDoppler.expectedPSV")}
                </BasicLabel>
                <BasicOutput
                  value={state.anemiaValues.cerebralDoppler.expectedPSV}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="mom">{t("anemia.cerebralDoppler.mom")}</BasicLabel>
                <BasicOutput
                  value={state.anemiaValues.cerebralDoppler.mom}
                  length={100}
                />
              </InLineLabel>

              <InLineLabel>
                <BasicLabel name="anemiaLevel">
                  {t("anemia.cerebralDoppler.anemiaLevel")}
                </BasicLabel>
                <BasicOutput
                  value={state.anemiaValues.cerebralDoppler.anemiaLevel}
                  length={100}
                />
              </InLineLabel>
            </Form>
          </StyledBodyContainer>
        </DropDownBody>
      )}
    </StyledCerebralDoppler>
  )
}

export default CerebralDoppler
