import React from "react"
import RadioButton from "../RadioButton"
import {StyledContainer, StyledErrorMessage, StyledGender} from "./styles"
import {useTranslation} from "react-i18next"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"
import {ErrorMessage} from "@hookform/error-message"

export type genders = "female" | "male" | ""

type GenderRadioProps = {
  value: genders
  setValue: (value: string) => void
  label: string
  register: UseFormRegister<FieldValues>
  errors?: FieldErrorsImpl<FieldValues>
}

const GenderRadio = ({
  value,
  setValue,
  label,
  register,
  errors,
}: GenderRadioProps) => {
  const {t} = useTranslation("translation")
  return (
    <StyledContainer>
      <StyledGender>
        <RadioButton
          register={register}
          value="female"
          text={t("buttons.female")}
          checked={value == "female"}
          setValue={() => setValue("female")}
          id={t("buttons.female") + label}
          name={label}
        />
        <RadioButton
          register={register}
          value="male"
          text={t("buttons.male")}
          checked={value == "male"}
          setValue={() => setValue("male")}
          id={t("buttons.male") + label}
          name={label}
        />
      </StyledGender>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={label}
          render={({message}) => <StyledErrorMessage>{message}</StyledErrorMessage>}
        />
      )}
    </StyledContainer>
  )
}

export default GenderRadio
