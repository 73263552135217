import * as yup from "yup"

const LHR = yup.object({
  weeks: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 10", (val) => Number(val) >= 10)
    .test("range", "Cant be higher than 44", (val) => Number(val) <= 44),
  days: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Can only be 6 or lower", (val) => Number(val) <= 6),
  longitudinal: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 4", (val) => Number(val) >= 4)
    .test("range", "Cant be higher than 90", (val) => Number(val) <= 90),
  transversal: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 2", (val) => Number(val) >= 2)
    .test("range", "Cant be higher than 70", (val) => Number(val) <= 70),
  circumference: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits"),
})

export default LHR
