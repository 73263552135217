import React from "react"
import {StyledConfirmedLMP} from "./styles"
import {useTranslation} from "react-i18next"
import Form from "../../../../components/Form"
import {InColumnLabel, InLineLabel} from "../../../../components/LabelContainer"
import BasicLabel from "../../../../components/BasicLabel"
import BasicInput from "../../../../components/BasicInput"
import BasicOutput from "../../../../components/BasicOutput"
import {StyledBlackText} from "../../../../components/Texts/styles"
import LPM from "../../../../components/Form/Validations/LPM"
import {useStore} from "../../../../store"
import {MonochronicTwinsActionKind} from "../../../../store/Reducers/Modules/monochronicTwins"
import {FieldValues, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"

const ConfirmedLMP = (): JSX.Element => {
  const {t} = useTranslation("translation")
  const {dispatch, state} = useStore()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(LPM),
  })
  const submitHandler = (values: FieldValues) => {
    console.log(values)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateOutputs = (output: string) => {
    dispatch({
      action: MonochronicTwinsActionKind.UPDATE_GA_BY_LMP,
      payload: output,
    })
  }

  return (
    <StyledConfirmedLMP>
      <Form handler={handleSubmit(submitHandler)}>
        <InColumnLabel>
          <BasicLabel name="ConfirmedLMP">
            {t("monochronicTwins.diagnosticAlgorithm.lmp.confirmedInputLabel")}
          </BasicLabel>
          <BasicInput
            errors={errors}
            register={register}
            type="date"
            name="ConfirmedLMP"
            length={95}
            value={state.monochronicTwinsValues.confirmedLMP}
            setValue={(value) =>
              dispatch({
                action: MonochronicTwinsActionKind.UPDATE_CONFIRMED_LMP,
                payload: value,
              })
            }
          />
        </InColumnLabel>

        <InLineLabel>
          <BasicLabel name="gaByLmp">
            <StyledBlackText>
              {t("monochronicTwins.diagnosticAlgorithm.lmp.gaOutput")}
            </StyledBlackText>
          </BasicLabel>
          <BasicOutput value={state.monochronicTwinsValues.gaByLMP} length={100} />
        </InLineLabel>
      </Form>
    </StyledConfirmedLMP>
  )
}

export default ConfirmedLMP
