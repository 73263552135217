import * as yup from "yup"

const POSSchema = yup.object({
  lungToHead: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("range", "Cant be lower than 1", (val) => Number(val) >= 1)
    .test("range", "Cant be higher than 100", (val) => Number(val) <= 100),
  side: yup.string().required("Required"),
  liverUp: yup.string().when("side", {
    is: "left",
    then: (schema) =>
      schema
        .required("Required")
        .matches(/^[0-1]+$/, "Must be only digits")
        .test(
          "range",
          "Can only be 0 or 1",
          (val) => Number(val) == 0 || Number(val) == 1
        ),
  }),
})

export default POSSchema
