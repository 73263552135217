import {ErrorMessage} from "@hookform/error-message"
import React, {useRef} from "react"
import {FieldErrorsImpl, FieldValues, UseFormRegister} from "react-hook-form"
import Calendar from "../Calendar"
import {
  StyledBasicInput,
  StyledContainer,
  StyledErrorMessage,
  StyledInputContainer,
} from "./styles"

export type InputProps = {
  length?: number
  disabled?: boolean
  name: string
  type: "date" | "tel"
  value: string
  setValue: (value: string) => void
  register: UseFormRegister<FieldValues>
  errors?: FieldErrorsImpl<FieldValues>
}

const BasicInput = ({
  errors,
  length = 86,
  name,
  disabled = false,
  type,
  value,
  setValue,
  register,
}: InputProps): JSX.Element => {
  const {ref, ...restOfRegisterProps} = register(name)
  // Function to only accept numbers when it's a text input
  const validate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]*\.?\d*$/
    if (type == "tel" && !regex.test(e.target.value)) {
      e.preventDefault()
    } else {
      setValue(e.target.value)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>(null)

  const onClick = () => {
    // Funciona, pero TS lo toma como error
    const input = inputRef.current // as HTMLInputElement | null
    if (input !== null && "showPicker" in HTMLInputElement.prototype) {
      input.showPicker()
    }
  }

  const error = errors == null ? false : errors[`${name}`] != null

  return (
    <StyledContainer>
      <StyledInputContainer>
        <StyledBasicInput
          errors={error}
          {...restOfRegisterProps}
          disabled={disabled}
          ref={(element: HTMLInputElement) => {
            ref(element)
            inputRef.current = element
          }}
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={(event) => validate(event)}
          length={length}
        />
        {type == "date" && <Calendar toggle={onClick} disabled={disabled} />}
      </StyledInputContainer>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({message}) => <StyledErrorMessage>{message}</StyledErrorMessage>}
        />
      )}
    </StyledContainer>
  )
}

export default BasicInput
