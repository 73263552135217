type epidemiologicType = {
  parity: {[key: string]: number}
  previousPE: {[key: string]: number}
  hypertension: {[key: string]: number}
  renalDisease: {[key: string]: number}
  ethnicGroup: {[key: string]: number}
}

const epidemiologic: epidemiologicType = {
  parity: {"": 0},
  previousPE: {"": 0},
  hypertension: {"": 0},
  renalDisease: {"": 0},
  ethnicGroup: {"": 0},
}

type biometricType = {
  age: string
  height: string
  weight: string
}

const biometric = {
  age: "",
  height: "",
  weight: "",
}

type ftdType = {
  crl: string
  systolicBP: string
  diastolicBP: string
  ipmaut: string
}

const ftd = {
  crl: "",
  systolicBP: "",
  diastolicBP: "",
  ipmaut: "",
}

export type PreeclampsiaInitStateType = {
  epidemiologic: epidemiologicType
  biometric: biometricType
  ftd: ftdType
  output: string
}

const preeclampsiaInitState = {
  preeclampsiaValues: {
    epidemiologic: epidemiologic,
    biometric: biometric,
    ftd: ftd,
    output: "",
  },
}

export default preeclampsiaInitState
